import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { CandidateCard } from "./MetaPolicyExplorer";
import {
  candidatesAlphabetical,
  policiesByCandidateAndCategory,
} from "./policies";

export const CandidateExplorer = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal
      isOpen={true}
      onClose={console.log.bind(null, "onClose")}
      size="full"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Explore Candidates</ModalHeader>
        <ModalBody backgroundColor="gray.200" padding={0}>
          <Flex
            flexDirection="column"
            flex={1}
            gap={4}
            padding={4}
            maxWidth="120ch"
            margin="auto"
          >
            {candidatesAlphabetical.map((candidate) => {
              const policyCategories = policiesByCandidateAndCategory.get(
                candidate.id
              );

              return (
                <CandidateCard
                  key={candidate.id}
                  name={candidate.name}
                  policyCategories={policyCategories || []}
                  hideCheckbox
                ></CandidateCard>
              );
            })}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Back</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
