[
    {
        "name": "Housing",
        "metaPolicies": [
            {
                "name": "Increase rental stock",
                "policies": [
                    {
                        "id": "1bb789d0-6c69-446a-9037-728b3863a308",
                        "statement": "Provide incentives for construction of rental homes",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "de45ec1d-d1e9-4cd2-a8a7-3a93f10a5087",
                        "statement": "Introduce \u2018rental zoning\u2019 to prioritize rentals",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "6fd282a3-f61c-4bae-a3e7-01d8a494fac3",
                        "statement": "Explore innovative housing solutions, such as modular construction and tiny homes, to increase the housing supply cost-effectively",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "f106a609-5094-4211-81ca-de253e476afa",
                        "statement": "Ban AirBnB bringing 20,000 rental units back on the market",
                        "candidateId": "r.davis"
                    },
                    {
                        "id": "0c849a6a-a0ac-4f95-a5e1-a82718d93be1",
                        "statement": "Create 1,000 new rent supplements to help people secure permanent housing",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "980e5852-0172-4340-8cc9-cc6655951020",
                        "statement": "Permit rental apartments on major streets",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "bc0a70e9-fe58-4b02-b72a-9f775ed70a2b",
                        "statement": "Aggressive inclusionary zoning by 50% of newly-constructed condos to be rented at market-median rate",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "89f05408-313b-4763-8477-5a8c4fbd2ac0",
                        "statement": "Speed up building approvals and cut red tape by implementing a conditional approval process",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "b0b5de01-9180-49eb-9756-6dea4dff7d0f",
                        "statement": "Building information modelling and AI to improve building performance and efficiency",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "8f903de5-7008-49f8-a2e2-c8a5b6f51e26",
                        "statement": "Temporarily freeze new development proposals that would see existing apartment rentals demolished",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Increase housing density",
                "policies": [
                    {
                        "id": "8eef493a-9e22-41b2-93c5-045931fa4f87",
                        "statement": "Support building multiplexes",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "63981b55-a0b1-438e-a7c7-c48bc1014794",
                        "statement": "Support mid-rise housing units",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "a42c9c8d-f054-4ac4-8e1e-3e8de96b7fce",
                        "statement": "Get more housing built by accelerating approvals, in part by eliminating \u201csilos\u201d at city hall and digitizing more of the process",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "92a8dae1-03af-4217-8ef5-7b43da183fd3",
                        "statement": "Provide incentives to build multiplexes across the city",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "963e0d59-3ebf-4cb7-acc5-46f0bc89630b",
                        "statement": "Propose a tax on speculators who have approved permits but are not building",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "156aa491-6985-4d97-9744-50f9408432a9",
                        "statement": "Approve 9-storey buildings on designated avenues",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "d803d3d6-0e92-4808-a2a5-d66e71ec1c46",
                        "statement": "Allow up to three rental units in an existing home",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "80198cc3-f39b-40d8-bb2b-25c3633f09a4",
                        "statement": "Allow offices to be converted to housing as of right if the building isn\u2019t being expanded",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "86e9d70c-df6b-4c47-b6e4-b19a249106e9",
                        "statement": "Build more homes, using re-zoning tools such as 'upzoning' on avenues or establishing rental zones",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "d59cf62c-1dd7-40e9-bb4d-902d9a6e43f5",
                        "statement": "Implement aggressive zoning reform for more mixed use",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "b34af9f7-0ee4-4739-bcf1-c194e81c74d9",
                        "statement": "Re-zone to allow more types of housing in more neighbourhoods",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "0b4c261c-cee0-4420-b1a4-bffddc082f1c",
                        "statement": "Review density and height limits for housing on major arteries;",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Decrease housing density",
                "policies": [
                    {
                        "id": "483f7940-13e2-4748-8af8-40e5e5ee9975",
                        "statement": "Slow down the mass construction of condos in the downtown core",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "41142a4e-b531-461a-8915-ac0a530fcc1b",
                        "statement": "slow the cities growth by opposing multiplex development",
                        "candidateId": "k.langenfeld"
                    }
                ]
            },
            {
                "name": "Housing homeless people",
                "policies": [
                    {
                        "id": "9cd161e0-34a5-4963-b7cb-bb8f8193d94d",
                        "statement": "Retrofit vacant commercial and industrial units to house homeless, elderly, mentally unwell, drug addicts etc.",
                        "candidateId": "k.langenfeld"
                    },
                    {
                        "id": "21158051-248a-4263-9f83-87e30153ef46",
                        "statement": "Create an emergency solution center for the homeless and create a personal care program for each homeless torontonian",
                        "candidateId": "s.korovitsyn"
                    },
                    {
                        "id": "ab1e1a29-3deb-4b78-89bf-e6e2fde20f10",
                        "statement": "Provide safe housing to more people by introducing rapid shelters like sleeping pods and modular housing.",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "246846ef-186a-48a1-83ea-c06219eb7ece",
                        "statement": "Building more homes to end homelessness",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "a73a68bb-08c1-4f67-8725-145ac85d68db",
                        "statement": "Prioritize housing that caters to homeless population",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "bb32296c-cddf-4944-9839-7abd6683e966",
                        "statement": "Create new rent supplements for homeless",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "77ebac9b-82da-45b9-91e3-c0b3d144a61d",
                        "statement": "Repeal code 608 (the no-camping bylaw)",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "053842f8-3ec1-42df-b475-56d73c5b1745",
                        "statement": "Housing first strategy to end homelessness",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "d58a3cd3-c2fa-42ac-b16a-47c767dfa05f",
                        "statement": "Open a year-round respite service for homeless",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "cd115490-0c64-4e79-8305-1951cb5087cd",
                        "statement": "Convene emergency summit with Federal and Provincial Government over homelessness",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "8e3f6176-d07d-4d99-ad70-136949459dba",
                        "statement": "Every Toronto resident homeless gets housing",
                        "candidateId": "a.alam"
                    },
                    {
                        "id": "47eeb19f-8e56-47c7-82c5-e3fc77db5d56",
                        "statement": "Develop rapid housing solutions for the Shelters 2 Homes programs to support individuals with increased funding to retain social workers and mental health specialists",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "316f737c-c6b0-4329-9f18-521cc7f6efff",
                        "statement": "Increase funding for supportive housing programs that provide wrap-around services, including counseling, addiction support, and mental health services",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "9a99ab74-6b2e-400d-a8ff-2a484a9f3a3a",
                        "statement": "Provide more housing options and support services for homeless",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "2f0940c1-8e67-443a-abeb-a1cd15e92ed7",
                        "statement": "Review the streets to home outreach program,",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "3791ec34-1b48-4ff5-8c6d-b6eed5465641",
                        "statement": "Increase funding to shelters",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Increasing and maintaining affordable housing stock",
                "policies": [
                    {
                        "id": "ae911279-1a32-4560-ae6e-b692b89c572e",
                        "statement": "Streamline the development approval process to expedite the construction of new affordable housing units",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "b154db1d-5c7a-4e2d-b0f2-e92a89d906b3",
                        "statement": "Use renovation subsidy and interest free loan to incentivize apartment creation in single family homes",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "fa785ae7-510e-4444-b61e-dfa86c567a08",
                        "statement": "Building 25,000 units of rent-controlled housing, with at minimum 7,500 affordable units, including at least 2,500 rent-geared-to-income units",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "3dc21480-b522-43e2-b1b5-6af2a633c764",
                        "statement": "Facilitate construction of more purpose-built affordable housing units",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "fd22295d-bde4-45cd-93bf-76a137919fb9",
                        "statement": "Build 5000 units of housing in two years, capping rent at $1000 or less",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "69fb55c1-08ed-4a7d-9f27-c5668f5347f8",
                        "statement": "Require condos to have 5% of units as affordable housing",
                        "candidateId": "k.langenfeld"
                    },
                    {
                        "id": "e1b34db2-a539-48c7-b588-1e97b8a33498",
                        "statement": "Create secure affordable homes fund",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "7ecc2cc0-6842-437e-8760-aaf0bf785c31",
                        "statement": "Develop 90,000 affordable units within 5 years",
                        "candidateId": "e.sniedzins"
                    },
                    {
                        "id": "ab14c62f-dcff-410a-8f41-43ae1c83214d",
                        "statement": "Subsidize low-income families",
                        "candidateId": "p.handjis"
                    },
                    {
                        "id": "a3efbd78-fd1c-4900-9057-a5f97a92e239",
                        "statement": "More affordable housing",
                        "candidateId": "c.saccoccia"
                    },
                    {
                        "id": "2726114b-ab3b-46a8-a915-d289bf0c450c",
                        "statement": "Construct sustainable and affordable housing",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "8b3743a9-6c47-4e4a-8b37-72ab7cf7da07",
                        "statement": "Preserving Existing Affordable Housing Through Conditional Demolition and Building Permits",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "a2a7dc18-05b3-470f-8806-00b580e640b9",
                        "statement": "Re-zone business areas to build affordable housing",
                        "candidateId": "b.acton"
                    },
                    {
                        "id": "4a233293-2a8f-4b45-8dc1-91e3fd9b8fec",
                        "statement": "Bolster low-income rental options",
                        "candidateId": "p.handjis"
                    },
                    {
                        "id": "06340f6b-28c2-49ae-8c0a-5da00faa171b",
                        "statement": "Build cheap and fast housing using shipping containers",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "88fb20cb-4332-4e2a-9a5a-fe82d9cb3145",
                        "statement": "Cut red tape and speed up approval times for affordable housing",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "30e88617-c7a5-472f-bb02-b65a70029a3b",
                        "statement": "Re-zone to build more low and mid-rise affordable housing units",
                        "candidateId": "b.acton"
                    },
                    {
                        "id": "7a93c82f-f9ec-4a6f-a638-5a846d594a7f",
                        "statement": "Collaborate with financial institutions to develop affordable mortgage products tailored to low- and moderate-income households",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "b383926b-f2f3-4541-9a3e-2861533cad46",
                        "statement": "Triple spending on Rent Bank",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "25647cc2-797f-4836-9bf1-b266ba8852d6",
                        "statement": "Reduce cost of housing for everyone using rent vouchers and subsidies",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "0ba21320-fbd0-4da8-8694-84d3293d08c2",
                        "statement": "Encourage affordable housing initiatives",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "94bf9358-0401-4b09-b89d-91fbc34e817a",
                        "statement": "Prioritize development of affordable housing, creating incentives for developers",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "375349de-83fa-4857-b8a3-704d8deae16f",
                        "statement": "Collaborate with private developers to incentivize the inclusion of affordable housing units in new residential developments",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "ea975008-47c0-4353-a9ad-5a5511fb9b18",
                        "statement": "Expand rent subsidy programs to assist low-income individuals and families in accessing affordable rental units",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "ed1b5b7a-4109-487b-a9db-4342c3f6f548",
                        "statement": "Work with landlords and property managers to establish suitable rent prices, make rent adjustments, and establish community land trusts.",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "d65e5454-6cb2-4667-9326-1137a1bdc662",
                        "statement": "Increase funding for rent supplement programs to bridge the gap between market rents and tenants' ability to pay",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "d6b1b381-70f0-4e43-afda-14eebbe25033",
                        "statement": "Establishing the Secure Affordable Homes fund to stop renovictions by helping purchase affordable units and transfer them to not-for-profit housing providers",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "bd886a8c-4ef6-49df-85a3-67ca82c91bcc",
                        "statement": "Doubling the reach of Toronto\u2019s Rent Bank",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "c0f624c5-8806-4abf-bec9-683d676e81be",
                        "statement": "Triple annual Rent Bank funding to $15 million.",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "57e75124-5b28-4383-8b0e-4c1d8f9d5516",
                        "statement": "Advocate for stronger tenant rights and protections to ensure fair and affordable housing for all",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "98befe30-17e7-4be9-b501-78844f550245",
                        "statement": "Explore options for community land trusts and cooperative housing models to increase affordable homeownership opportunities",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "c80fba3b-c7bb-42c4-83cd-77099bd5cb4d",
                        "statement": "Bolster community land trusts and support for housing cooperatives",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "520f2c8a-fe5e-4103-a1c2-03e64f8eded0",
                        "statement": "promise to establish community land trusts that offer affordable spaces for artistic and cultural activities",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "02f290a8-dbee-40e7-af8c-cb0002fc53e6",
                        "statement": "Establish more community land trusts prioritizing mixed-use zoning, universal design, urban markets and farms, transit-oriented development, accessible healthcare systems",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Increasing housing by using city-owned property",
                "policies": [
                    {
                        "id": "ea866653-c3db-413a-891d-9513fbba3826",
                        "statement": "Create a Toronto Affordable Housing Corporation to build 108 new developments on city land ensuring 68% are affordable",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "53cb9294-41f0-4a58-8966-a37bea9775a1",
                        "statement": "All expropriated housing would be rented by city to tenants as non-profits",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "9ce9586e-fc62-4dac-8a1f-05398f41e531",
                        "statement": "Assess city-owned assets to see what can be turned into housing",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "7c5c2315-d312-46d6-ba7e-ffc54d37da5b",
                        "statement": "Unlock public lands to build more affordable housing",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "da9016c2-d65a-4ab9-8be5-b8a00c94fc2f",
                        "statement": "Use city-owned land to construct affordable housing",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "9a3df10c-71e3-4fb2-9337-d460a6eb9210",
                        "statement": "Allocating city-owned land to be developed by non-profits as co-op, supportive and affordable housing",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "96a4c8f7-3c77-4c4e-93de-d428ff266c2c",
                        "statement": "Establish agency responsible for building housing on city-owned land funded with $300M in seed money from the city, and waiving all municipal fees to incentivize construction",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "d4c98c2f-eb17-44d8-b5fc-0dc756808ba2",
                        "statement": "Spend $375 million to construct houses, triplexes, fourplexes and low-rise buildings on City land for low to middle-income families in Scarborough, Etobicoke, and North York",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "f38159b1-65db-4743-9e62-1b83229e4e26",
                        "statement": "Add more co-op housing options by using city land",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "033c8f66-4bb2-468d-971e-d48ad4e4e76c",
                        "statement": "Unlock government-owned lands to construct housing",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "f21d3376-8045-40b9-9801-18b3f2c2ea30",
                        "statement": "Build housing faster on unused city land",
                        "candidateId": "p.dcruze"
                    },
                    {
                        "id": "7ab8f528-a42b-47a4-908d-2d29585f709d",
                        "statement": "Building new housing on 21 vacant city-owned lots and streamlining approvals for affordable and city-led projects by cutting red tape",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "3e25bd9b-53c9-4782-8ffb-4699f25dfd49",
                        "statement": "Create houses and camping grounds in municipal parking lots",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "9df2d274-916e-4436-9c85-e3e37b5adf12",
                        "statement": "Make more surplus city-owned properties available for non-profit and co-op housing",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "1caaca57-bb91-4443-9e8c-3233b00c9667",
                        "statement": "Identify underutilized public lands for the construction of affordable housing projects",
                        "candidateId": "g.mammoliti"
                    }
                ]
            },
            {
                "name": "Add Rent Controls",
                "policies": [
                    {
                        "id": "282581cf-d03f-44dd-ad0c-41ace9c93c84",
                        "statement": "Units that receive city funding would be rent controlled",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "b9c0666a-155f-494c-b1af-a711fb3364a8",
                        "statement": "Monitor and enforce rent control regulations to prevent rent gouging and unfair practices.",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "c7aea7aa-1479-4019-8563-3b819df8e03f",
                        "statement": "Introduce rent control/freeze",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "5bd7c856-79d1-4d34-b08b-66b717ef01f1",
                        "statement": "Lobby the province to bring back rent controls for all tenants",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "66381e51-5b15-482e-ab30-1fa51d68f5aa",
                        "statement": "Ensure all rentals on city land subject to rent controls",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "2f94f062-4b73-4a77-ab51-9c7ee00e20b4",
                        "statement": "Implement rental control measures to stabilize rents and protect tenants from unjustifiable increases",
                        "candidateId": "g.mammoliti"
                    }
                ]
            },
            {
                "name": "Increase rental eviction protection",
                "policies": [
                    {
                        "id": "fae99d8c-ec30-4345-89b4-7ee88a21d97c",
                        "statement": "Scaling up the Toronto Tenant Support Program to fight evictions",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "fdf0064d-0cc9-4449-9643-9dcc512784de",
                        "statement": "Tripling the reach of the Eviction Prevention in the Community program",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "e24ceb79-14e7-4560-9a3c-b88152831b08",
                        "statement": "Add six new staffers to boost the city\u2019s eviction prevention program",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "8f121870-edc1-4a70-9662-13c9e9fa7e8b",
                        "statement": "Require anyone looking to renovate a property to have obtained a building permit before telling tenants they need to vacate",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "93176ab0-fe68-4764-bbff-951b95d3d9a1",
                        "statement": "Establishing the Renters Action Committee to give renters power at City Hall creating a strong anti-renovictions by-law and advocating for real rent control",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "388d17a2-face-4d2f-8c9f-58e270aeb24f",
                        "statement": "First right of refusal for renters who have been renovicted, can reapply to rent at the same rate as before; landlord must provide accommodations during construction",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "a520ea8e-50b4-44d4-8724-8cb2e2b54cd8",
                        "statement": "Establish a \u201cfraud squad\u201d to go after landlords attempting illegal renovictions or own-use evictions",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Other renter protections",
                "policies": [
                    {
                        "id": "8786bdd5-5fa7-407e-adab-95dd5a9e3eae",
                        "statement": "Hire more city-funded contractors to make critical repairs if a landlord refuses to do so.",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "fbf44346-5c38-41a8-af25-a2fa6b65bde3",
                        "statement": "Raise fines on landlords for improper repairs or no repairs",
                        "candidateId": "p.dcruze"
                    },
                    {
                        "id": "90c18d6b-276f-47a0-97a9-54049a651ca2",
                        "statement": "Renter Protection",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "b2baebd1-07bc-4abb-8982-9ca994fb5078",
                        "statement": "Increasing penalties for property ownership abuse",
                        "candidateId": "m.charlton"
                    },
                    {
                        "id": "68ef086a-7b5b-47c7-a379-3868aac04ea8",
                        "statement": "Increase fines to incentivize landlords to make repairs",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "ff2bd6c1-41d2-4b1e-896d-41b1b67b0683",
                        "statement": "Create a tenant support program",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "f934d004-4832-41fa-ab4e-c6f51785cf01",
                        "statement": "Develop an Online complaint and reporting systems for tenants and landlords",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Other housing policies",
                "policies": [
                    {
                        "id": "7068ccd9-6c6f-4371-a2a9-951ed8fda9f8",
                        "statement": "Limit individuals to 2 primary residences through a\u00a0public registry of property ownership",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "389e8bdd-30d4-48ce-9ed8-7ac069246d33",
                        "statement": "20% Foreign Property Funded Fee",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "cb96270c-6f2d-4231-8409-f8fed20b099d",
                        "statement": "Eliminate blind bidding on property sales",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "28391fa3-ed96-47e4-b992-10627a200047",
                        "statement": "Develop policies to address discriminatory practices in housing, promoting equal access and opportunities for all residents",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "cc419980-b9fb-4f10-b35a-b31861a7dfe6",
                        "statement": "Develop digital lease agreements that comply with local regulations",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "cb355335-8656-402d-a26b-ad2d7360c1ea",
                        "statement": "Abolish RentSafeTO, a bylaw enforcement program that aims to ensure building owners and operators are meeting maintenance standards",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "826a2ebe-435b-49d2-941d-eb9808855e28",
                        "statement": "Create a \u201cTrusted Landlord\u201d registry to speed up work approvals.",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "3bf5f16d-f661-4d09-94f8-aa312dacca86",
                        "statement": "People get priority to get home in the neighborhood of workplace",
                        "candidateId": "a.alam"
                    },
                    {
                        "id": "ee2dc70a-7cd9-4220-827a-384cbca62934",
                        "statement": "Merge data from Rent Bank and Housing Help Centres with market trends and property values to better utilize tenant-reported cost of living",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "b00c160b-796f-4120-9106-1b50d0b0c4a8",
                        "statement": "New projects would have space for grocery stores, small businesses and other amenities so residents can work close to home",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "a9417b27-9631-4b81-bb36-38dc20b0a5df",
                        "statement": "Repair and renovate existing housing stock",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "cbe0b136-b24b-4980-910a-9912437df0fb",
                        "statement": "All development applications would be approved within six months",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Increase rental safety through housing inspections",
                "policies": [
                    {
                        "id": "2cd2b9ce-c3e2-4723-823f-adf2550cf2ce",
                        "statement": "Hire more housing inspectors",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "320d3b8f-2172-483c-bace-c9ae79992873",
                        "statement": "Hire 15 more building inspectors to enforce property standards for pest prevention and temperature control.",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "23951979-a616-426c-9f04-8f69bf42417e",
                        "statement": "Promised additional bylaw officers to give RentSafeTO more teeth",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "c24a7966-aae3-4a37-bdf3-cc40a71d073d",
                        "statement": "Hire 25 additional inspectors would help staff a new Rental Housing Integrity Unit",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "bc24668d-11f6-4df8-85a8-be5683c3c72a",
                        "statement": "Colour-coded RentSafe signs \u2014 similar to Toronto\u2019s DineSafe program \u2014 for buildings at least three storeys tall and with 10 units minimum",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "c0f38766-1f59-45ab-97ba-a125d0af3626",
                        "statement": "Enforce strict property maintenance standards including regular inspections of buildings to identify any maintenance issues",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "fe0f831e-c181-4b9e-83c2-178615750386",
                        "statement": "Give RentSafeTO real teeth by investing in more by-law enforcement officers and staff to make sure people have healthy and safe living conditions",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "63e51a5f-aee2-4ad4-9059-bbc844fa1012",
                        "statement": "Ensure renters have access to safe and healthy living conditions",
                        "candidateId": "b.abdusalam"
                    }
                ]
            },
            {
                "name": "Increase safety during extreme heat",
                "policies": [
                    {
                        "id": "211080fe-fff5-40c7-b922-5732bbc0c7ea",
                        "statement": "Mandate a \u201ccool respite\u201d room in every apartment building",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "c6531e8e-6b50-4b9a-a719-01d186b59441",
                        "statement": "Consider Air conditioning as a human right and require landlords to provide it",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "4e03e4de-fc4b-438e-b6e5-d9f7e0ae0fe8",
                        "statement": "Impose new bylaw that would require buildings be 26 C or lower",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "976597da-be65-4961-9f5a-d2dbe00b5e62",
                        "statement": "Provide air conditioning units to seniors in Toronto Community Housing",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "02c7df58-ba97-42a4-bb89-ef26db09f6f3",
                        "statement": "Ramp up enforcement of RentSafeTO requirements to ensure tenants are notified of on-site and nearby cooling spaces",
                        "candidateId": "o.chow"
                    }
                ]
            }
        ]
    },
    {
        "name": "Governance",
        "metaPolicies": [
            {
                "name": "Leadership Reform and Education",
                "policies": [
                    {
                        "id": "ced927a0-a294-4d47-a68a-6a394049977b",
                        "statement": "Implement youth mayor and councillor program in school to teach children about the responsibilities of governance",
                        "candidateId": "p.dcruze"
                    },
                    {
                        "id": "85902b79-ab44-4181-8331-5e3e8665824a",
                        "statement": "Embrace diversity in leadership positions",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "9d225bc2-1720-41d7-bc71-21a7ad8ae41d",
                        "statement": "Appointing a \"Snow Czar\" or \"Snow Mayor\" to bring ideas to life from the Winter",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Addressing Budget Deficit",
                "policies": [
                    {
                        "id": "2b9011bc-fded-4567-9ef9-7658ceaf964a",
                        "statement": "Fiscal responsibility",
                        "candidateId": "g.cohen"
                    },
                    {
                        "id": "1820651f-c1d1-47cf-8c82-e024c7035d5a",
                        "statement": "Generate 1bn revenue annually without a plan",
                        "candidateId": "a.alam"
                    },
                    {
                        "id": "1edf4bb5-edca-4652-8478-22918f478dd8",
                        "statement": "New tolls and auction naming rights of public infrastructure;",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "56ab82b4-9747-4e37-a6a7-665dfd9293df",
                        "statement": "Negotiate a better deal with ride-sharing apps;",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "d602f792-e46f-4e8c-ad7c-09d98e2f722d",
                        "statement": "Rent out under-utilized city land like parking lots",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "ea1224b5-83c7-4756-91cb-ba37c152cb3a",
                        "statement": "Sell city-owned land",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "4fb7d08c-327d-4713-809c-570503391996",
                        "statement": "Cut $500 million from public sector to balance budget",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "a761c27e-5314-40a7-9986-4da64bc90406",
                        "statement": "Ask Bank of Canada for interest free loans to address budget shortfall",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "ca0f16ee-7cb4-4ba2-bebc-f6677d5365e3",
                        "statement": "Help address budget shortfall by inventing a new sport called Fastball which is a mix of soccer, volleyball and basketball",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "77fa9d2b-41ca-45b9-88a0-71d2b874d984",
                        "statement": "Higher fees for parking;",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "5afb56cd-fb94-4597-87ca-33772c333fbf",
                        "statement": "Sell digital ad space on city website",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "79bb8cdd-900b-42a4-bcc8-9105da790078",
                        "statement": "Rationalize city spending to eliminate budget deficit",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "92948b6a-06bb-4b31-bf64-a2375c8ac987",
                        "statement": "Voluntary fares; donations pinned to tax deductions; parking fees",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "205e58bb-2e01-486d-b14d-063ae033719e",
                        "statement": "Establish a priority fund to allocate surplus funds towards critical needs identified by taxpayers",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "41bdf125-e049-4b50-82e3-511d1e4fa41c",
                        "statement": "Less government waste",
                        "candidateId": "c.saccoccia"
                    },
                    {
                        "id": "a7039220-0e81-4879-a3c6-63cc6ed4b3cb",
                        "statement": "Transition government to free open-source computer programs to save money",
                        "candidateId": "s.mann"
                    },
                    {
                        "id": "ee8c3ac8-3db1-49ef-90a9-a0abb51b3950",
                        "statement": "Will work  Mayoral term free of charge",
                        "candidateId": "j.winter"
                    },
                    {
                        "id": "0a6b9221-c54a-4bf5-98f3-80cd7c90a49b",
                        "statement": "Budget cuts from the top, retiring old, overpaid civil servants",
                        "candidateId": "p.dcruze"
                    },
                    {
                        "id": "8739525a-0aa2-40aa-b01a-d05b491f17c6",
                        "statement": "Review every expense and program within the city's budget to identify waste and inefficiencies",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "e5dae23d-5940-443e-8736-a7e45cc60151",
                        "statement": "Targeting a 10-15 Percent Budget Reduction for Each Department",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "f0262336-0a2a-4451-be57-1c3314549e28",
                        "statement": "Explore other revenue tools including parking levies",
                        "candidateId": "c.caesarchavannes"
                    }
                ]
            },
            {
                "name": "Increasing Transparency and Accountability",
                "policies": [
                    {
                        "id": "caa47b5a-f785-486a-8fed-6fa95051b2ee",
                        "statement": "Create a Mayor's Hotline to make city hall more accountable",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "00a17622-4bea-4229-a4dd-c2b6fa23f32c",
                        "statement": "Publish all city contracts for public viewing, including union contracts",
                        "candidateId": "s.korovitsyn"
                    },
                    {
                        "id": "e713ccc5-6617-4ed7-80f3-532bb08878a8",
                        "statement": "Reveal and Justify all Budgetary Expenditures",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "2ce2082f-ecd6-42e8-88b1-16e8428477e7",
                        "statement": "Transparency in Governance and Budget",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "2848ec95-71a9-48e8-9d43-d552bd5e137f",
                        "statement": "Open government",
                        "candidateId": "j.winter"
                    },
                    {
                        "id": "08d34aa4-265f-4ed5-8a58-fa4db8605db4",
                        "statement": "Make city hall more welcoming and remove security checks",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "95b2fa3c-dbe7-4396-a109-f80fff1da4e8",
                        "statement": "Open tendering for city projects to save taxpayers money and encourage multiple bids",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "5f2d5f26-b010-4a05-a2f6-0f26bcf22116",
                        "statement": "Ending closed tendering for municipal contracts",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Reforming our Democracy",
                "policies": [
                    {
                        "id": "6f97b728-6d7b-4456-b67c-7e6a3fe404ec",
                        "statement": "Use participatory budgeting for entire city budget",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "d7cfd8a8-1721-4115-9a96-801fb6bcb5c2",
                        "statement": "Create a participatory budget process",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "b6518f7d-9ae6-41e3-ae64-df31730f8a1b",
                        "statement": "Aim for the common good and consensus and not force people to change their minds",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "7728fde9-3d9e-4068-9426-c43df5085ade",
                        "statement": "Politicians will be banned from kissing babies, because that is creepy.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "cffcc79e-7641-4414-add1-9732c11d5d00",
                        "statement": "Appointment of top mayoral candidates as consultants",
                        "candidateId": "a.alam"
                    },
                    {
                        "id": "7121e54e-0ea3-4221-ac30-6321f79103e6",
                        "statement": "City Councillors will be called 'City Clowncillors' for accuracy.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "f3eb711e-a1f5-4b92-b816-6ed185d4b775",
                        "statement": "Implement ranked ballots, and term limits for councillors",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "ce3bb5b8-f270-481c-bbf7-a915365a7de9",
                        "statement": "Restructure the megacity and replace the 2002 official plan",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "183f4d95-edbc-406a-93c8-ed9a11e8f2b6",
                        "statement": "Establishing stronger partnerships with China instead of federal government",
                        "candidateId": "w.tang"
                    },
                    {
                        "id": "e6f2109b-e6c8-44cd-9a24-2e8b24e2caa3",
                        "statement": "Implementing a  economic and political revolution modeled on China's planned economy",
                        "candidateId": "w.tang"
                    },
                    {
                        "id": "65788e86-2939-4fd6-989a-e7a49d572789",
                        "statement": "Introducing legislation to give the Mayor of Toronto omnipotent powers.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "00883eaa-698a-4fb0-a9b8-5b7afcfd9f76",
                        "statement": "Opposing strong mayor powers",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "41555d00-e3ae-4999-8f42-4dcf04fdd4f6",
                        "statement": "Never vetoing council decisions",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "ecadc4a7-481f-4b3c-ab06-dd3646d71e21",
                        "statement": "Strengthening transparency and accountability",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "c71f2a65-5f4c-48e4-961d-9da4c7b39896",
                        "statement": "Promoting local autonomy",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "083da373-8c57-4065-baf8-0d2568275568",
                        "statement": "Fire bureaucrats who don't listen to citizens",
                        "candidateId": "b.graff"
                    }
                ]
            }
        ]
    },
    {
        "name": "Economic development",
        "metaPolicies": [
            {
                "name": "Support Local and Small Business Development",
                "policies": [
                    {
                        "id": "4b42b802-1176-47c8-b779-ce330b0dbe6e",
                        "statement": "Relaxing restrictions on street food vending to allow more food entrepreneurs to set up shop",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "4c9d22ac-e7a8-4e1a-9507-9e779d1b7995",
                        "statement": "support businesses affected by LRT",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "262e3647-fc05-47ef-90e5-633b2e48fb29",
                        "statement": "create a 'smartstart' leadership initiative to train next generation of business leaders",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "6793f58f-6cab-45e3-b826-23b6d319ecbf",
                        "statement": "Expanding mixed-use developments like \u2018The Plant\u2019 to encourage more mixed-use developments",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "ed084169-c636-4491-919e-c2fb751ffa29",
                        "statement": "Bolster small business",
                        "candidateId": "p.handjis"
                    },
                    {
                        "id": "fff53d98-835e-42e9-bccb-aed4e492b76c",
                        "statement": "20% rent reduction for renters of residential and commercial spaces, locked in for 3 years",
                        "candidateId": "p.dcruze"
                    },
                    {
                        "id": "acaf521d-85e0-4e0b-ab6c-f84beaaa88fd",
                        "statement": "Municipal governments can use their purchasing power to support the development of relocalized supply chains that benefit local communities",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "2207b7ff-428c-471b-a238-db1c38eefcb1",
                        "statement": "Encouraging entrepreneurship by supporting incubators and accelerators",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "3529ed83-b2a5-4794-a91e-5d872d6835d5",
                        "statement": "Make toronto more accessible to business",
                        "candidateId": "s.korovitsyn"
                    },
                    {
                        "id": "b2d50314-1594-4b4e-a1eb-7d0f1274cd2d",
                        "statement": "Fair rents for commercial and residential properties using data",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "12bff56f-18bb-46cd-8c2c-7c549caf0aae",
                        "statement": "make temporary re-zoning bylaws to allow patios on parking lots",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "6c4e9ec7-5385-4a88-bc2d-5454bc1972be",
                        "statement": "work with Toronto Hydro for lower energy prices for industry",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Create Partnerships with all Levels of Government",
                "policies": [
                    {
                        "id": "6dc9b282-3ac7-4080-9759-54bf7f9629e8",
                        "statement": "lead a coalition of Ontario mayors to push the provincial or federal government to dedicate 1% of HST revenue to a new municipal transfer",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "76094ef3-9ced-4b29-8478-7780f3a6fa99",
                        "statement": "Committed to working with all levels of government, federal and provincial",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "1df03990-6bf8-4ad7-9a99-1a693ed0b220",
                        "statement": "Supporting collaboration between business, academia, research institutions and government",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "a095dc16-25b7-4ab6-8181-8ca01e509fb7",
                        "statement": "promised to sue Metrolinx and the province over the delays in opening the Eglinton Crosstown. any proceeds from the suit would go to businesses and BIAs along Eglinton Avenue",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "59108a73-c9c8-4b5e-bc00-4c9c4572a762",
                        "statement": "lobby the federal government for a carbon tax rebate on city gas costs",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "More efficient approvals processes",
                "policies": [
                    {
                        "id": "509c3849-c777-48ca-8356-0fd5485e3ab2",
                        "statement": "Cutting red tape for business; approvals should take days, not weeks or months",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "a3dc5167-6630-4fd1-867f-0b85d82764cd",
                        "statement": "Cut red tape",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "9038fb23-6910-42c4-a84d-4fafc256a508",
                        "statement": "Less red tape, fees, fines and penalties for small businesses",
                        "candidateId": "b.acton"
                    },
                    {
                        "id": "03811f7f-bbf0-4021-99f1-137759d38404",
                        "statement": "Freeze user fees for development for three years",
                        "candidateId": "a.perruzza"
                    }
                ]
            },
            {
                "name": "Adopt Financial Cryptography Technologies",
                "policies": [
                    {
                        "id": "36de0dc4-a64b-49c0-8d93-f0ca0a03325a",
                        "statement": "Establish ability to receive payments in crypto",
                        "candidateId": "s.korovitsyn"
                    },
                    {
                        "id": "8730e4fc-c3df-4463-9dbe-47ed38f63fa9",
                        "statement": "Turn toronto into global leader in crypto",
                        "candidateId": "x.gong"
                    }
                ]
            },
            {
                "name": "Eliminate CafeTO fees and improve application process",
                "policies": [
                    {
                        "id": "780e9b48-e83b-49e0-99a8-f4b8577c6a0a",
                        "statement": "Cancel and refund CafeTO license fees",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "5f52f9e0-3541-401b-bb2e-da962d22f63a",
                        "statement": "Refund application and permit fees for all businesses who are participating in this year\u2019s Caf\u00e9TO program",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "9de6cf60-4b6b-4940-97bf-6a829c0acdc4",
                        "statement": "Remove barriers so more restaurants could take part in CafeTO",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "09a2d136-c810-41ce-9f20-0718b1bf29b3",
                        "statement": "Waive this year\u2019s application and permit fees for CafeTO and to streamline the process for future summers",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "3625cf31-aad8-4fd9-a988-996b76ad9421",
                        "statement": "Eliminate fees for CafeTO program",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Expand Jobs, Training and Employment Opportunities",
                "policies": [
                    {
                        "id": "55f00952-4791-4868-81e2-ed026439917b",
                        "statement": "Require municipal projects to favor Toronto suppliers and manufacturers and to favor Toronto workers and apprentices",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "1d9d1b05-8b87-478c-b5f3-ddf71aca5b41",
                        "statement": "secure good local jobs through Community Benefit Agreements whenever it pursues construction projects, like building new transit",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "772f52d1-93c5-4105-af0d-e8011a0472d4",
                        "statement": "Reform welfare system using jobs training and skills development partnering with local business and industries",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "be106656-e168-4b80-871d-4796cf6815ea",
                        "statement": "expanding work and volunteer opportunities",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "d3aa5da7-b132-4a70-944c-7daff79773cd",
                        "statement": "Develop a training program to help gig workers involved in food delivery access sustainable wages through sustainable food-related jobs",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "19d8ede4-3edb-452c-a24a-ba540ef974c6",
                        "statement": "invest in vocational training that aligns with demands of labour market",
                        "candidateId": "g.mammoliti"
                    }
                ]
            },
            {
                "name": "Creating More Community Development Opportunities",
                "policies": [
                    {
                        "id": "e1d8b9c2-d5ea-4ec2-9ea3-e3253ca5f061",
                        "statement": "Create a new fund to expand services, and let the community lead by working with community agencies",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "f427daa2-e7e4-4a22-bc61-26b5f9f4da42",
                        "statement": "Support participatory budgeting to enable residents to directly influence the allocation of public resources",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "996c1202-5418-43b6-8813-d228b4b70cc8",
                        "statement": "Engage taxpayers through public consultations, and online platforms to gather their input  on priority areas for fund allocation",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "2ef1a75b-d769-4f75-87a0-9db049be2051",
                        "statement": "promote the use of community benefit agreements (CBAs) to ensure that community development projects to meet the needs of local residents.",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "72e97526-3bd9-40bd-b94c-42433cf10168",
                        "statement": "create a Give Back Fund to empower community councils with the funds they need to act on decisions at the community level",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "28755b8e-991e-452b-ab42-7b684e1461ab",
                        "statement": "Create a local stewards advisory committee program to foster development in community development projects",
                        "candidateId": "c.brown"
                    }
                ]
            }
        ]
    },
    {
        "name": "Property Taxes",
        "metaPolicies": [
            {
                "name": "Freeze Property Taxes",
                "policies": [
                    {
                        "id": "b1066029-8790-4d3a-a93b-4857e1bd9938",
                        "statement": "Keep property taxes below inflation",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "3f8966ab-7c6f-4cf8-af91-c346645a114d",
                        "statement": "Four year tax-freeze",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "abb42b99-7347-43b0-b3a6-6609c3484da6",
                        "statement": "No property tax increases",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "5c60be9f-6071-4aec-98f5-a9666aa89c84",
                        "statement": "Freeze property taxes for three years",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "f40fe17f-e74a-4657-956f-9987ace0860b",
                        "statement": "No new taxes",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Reduce Property Taxes",
                "policies": [
                    {
                        "id": "43aa3072-9ceb-4071-9c3a-5214bed49a01",
                        "statement": "Reduce property taxes",
                        "candidateId": "s.korovitsyn"
                    },
                    {
                        "id": "0be03fd3-99d2-48d6-93bd-d190fd8a2d1a",
                        "statement": "eliminate the municipal land transfer tax for first-time homebuyers and gradually phase out the tax entirely over a four-year period.",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "2a7aa2a9-874c-45e8-9308-3d415498a963",
                        "statement": "Eliminate vacant property tax increase",
                        "candidateId": "e.sniedzins"
                    },
                    {
                        "id": "e1c90ec8-0f31-4060-bc99-d9950500e529",
                        "statement": "Reduce taxes",
                        "candidateId": "e.sniedzins"
                    },
                    {
                        "id": "b7488926-7412-49c3-acea-ac9a60a8be8c",
                        "statement": "Implement a property tax deferral program, \u00a0where first-time homebuyers can defer 60% of their property taxes in first 5 years",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "d164944e-b721-4b41-a382-6b8ac110d89c",
                        "statement": "$1000 rebate per household on property taxes to be spent on repairs or services in Toronto;",
                        "candidateId": "x.gong"
                    },
                    {
                        "id": "6ddf5f78-047a-459c-8ba1-74ac8cb66fe6",
                        "statement": "Abolish property taxes for homeowners over the age of 80",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "c96c62df-3449-4580-82b3-7c6e79818d65",
                        "statement": "No more property taxes",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "ec78fdfc-6aae-44a7-80d7-960cfbce7ac1",
                        "statement": "Peg property tax increases to rate of inflation",
                        "candidateId": "k.langenfeld"
                    },
                    {
                        "id": "d4557ece-da55-44d3-82f8-36a8671826a6",
                        "statement": "Keep property tax increases at or below the rate of inflation",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "8b0ea0ac-cada-410d-8806-0cb5757830dd",
                        "statement": "Keep property tax increases at or below the rate of inflation",
                        "candidateId": "m.saunders"
                    }
                ]
            },
            {
                "name": "Increase Property Taxes",
                "policies": [
                    {
                        "id": "474aa9da-c60b-4a94-a706-cded7f9923d7",
                        "statement": "For low- and middle-income homeowners earning less than $80,000 annually, a 3% tax increase",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "de19b34c-e79b-4392-ae42-252036c99339",
                        "statement": "Implement luxury homes tax",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "bba3c966-ca67-4303-8d05-1a9b29db15c5",
                        "statement": "Increase property taxes by $67/year on average, and generate $390M over 5 years",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "882fdefc-f284-4fcb-9c36-8bc36d7e9068",
                        "statement": "Taxing empty homes and undeveloped properties",
                        "candidateId": "m.charlton"
                    },
                    {
                        "id": "3d730f9f-e744-4f6e-8b1b-1367b4cc26d3",
                        "statement": "Raise vacant homes tax from 1% to 3%",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "e90a0296-5271-4208-a08b-3824616cb781",
                        "statement": "Vacancy tax after 3 months at 30% of value, with expropriation after 2 years",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "2f0be9e4-5b7a-4eae-bbae-e1bf160e8702",
                        "statement": "Increase property taxes by 6% for 2024 and 2025",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "a7fef1b4-c257-4730-9e39-66cddc0906e9",
                        "statement": "Add 30% annual tax on investment properties",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "563d14af-7517-4b49-b0eb-513585f31599",
                        "statement": "Double the land transfer tax on the purchase of a second residential property",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "6fc3b4a6-4f17-49a4-8acd-3f5dfa84d5b5",
                        "statement": "Aggressive taxation of multiple property ownership",
                        "candidateId": "m.charlton"
                    }
                ]
            }
        ]
    },
    {
        "name": "TTC",
        "metaPolicies": [
            {
                "name": "Expand transit in Scarborough",
                "policies": [
                    {
                        "id": "24926b51-5e4b-4c99-9253-52efc5208d02",
                        "statement": "Fund and speed up the design of a bus rapid transit network to replace the Scarborough SRT when it shuts down later this year",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "1408b580-5b6e-49ca-b95f-bab300ac6afe",
                        "statement": "Build a Scarborough busway",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "afb9e843-59f2-4bee-8e43-21f55eca6966",
                        "statement": "Repurpose the separated corridor used by the Scarborough SRT for a rapid busway",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "dd075517-9489-4acf-9bf8-2070c6a72b43",
                        "statement": "Expand the Sheppard subway (Line 4) to connect with the coming Scarborough subway extension in the east and Sheppard West station on Line 1 in the west.",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "65714c7e-0495-40d1-9dca-04ad4d3f9e13",
                        "statement": "Build a rapid transit busway in scarborough to offset construction",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "d0171d30-7d25-455b-9af1-80514511cb0d",
                        "statement": "Replace the Scarborough SRT with a busway that would see buses run on a dedicated corridor \u2014 previously used by the SRT \u2014 from Kennedy to Ellesmere roads",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "6696fbb7-9e69-43f2-ae92-fbab238461cb",
                        "statement": "Accelerate plans for the extension of the Sheppard subway (Line 4) east to McCowan Road in Scarborough and west to connect with Sheppard West station on Line 1",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "27448fec-b784-4882-9c84-44d9d3b2e97c",
                        "statement": "Move ahead with the proposed Eglinton East LRT line from Kennedy Station to Malvern Town Centre.",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Other Transit Changes",
                "policies": [
                    {
                        "id": "9aea3775-d50b-40d2-a8a6-636fdcee5f79",
                        "statement": "Finish Ontario Line and Eglinton before starting any new projects",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "7ed5822b-3bbf-4998-813c-307393c45c90",
                        "statement": "King streetcar express zone, re-route all streetcar traffic during queen construction",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "59be075d-bca4-4c40-aeaa-01594a69eb96",
                        "statement": "Improve transit services",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "1ef3db95-0030-4565-8822-b5c89a880f14",
                        "statement": "Creating a TTC development corp",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "fb474514-3582-41d4-9700-1ef6ab7d4c39",
                        "statement": "Easy and cheap parking at suburban TTC hubs",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "6a01d109-6a50-4c2b-b0fe-a56b847b66c1",
                        "statement": "Replace all streetcars with busses",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "32b0082f-715a-43bb-888b-32378e9c3828",
                        "statement": "We will create new subways that go aboveground, are small, compact, and personal, and can go in any direction.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "be5937a7-0da1-4985-8f65-f549b932ce88",
                        "statement": "We will turn the old subway tunnels into a giant lazy river.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "fbdfff5f-c67e-4a6c-a641-614b59b14383",
                        "statement": "The walls of buses will be covered with scratch n' sniff, with every bus being a different smell. This will increase ridership on the TTC.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "cb62b943-6ba1-4411-8d1b-826aa6092ca0",
                        "statement": "Data-driven approach to transit",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "e41616f2-c905-43bc-8d96-e32b12357805",
                        "statement": "Replace metrolinx with city-run corporation",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "3fa905de-3cf9-4548-9be1-fa0a9054fa66",
                        "statement": "Request more convenient bus stops during off-peak hours",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "7dcc3e25-4959-459b-a861-6b944f706fc4",
                        "statement": "Rebuilding gardiner and DVP with TTC rapid transit plan",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "e13ea505-e8e8-45f0-956f-d7e29bd6a2a6",
                        "statement": "Shift Ontario Line from Queen to Richmond",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "b4290f60-f3de-4a5b-9631-3206910df8b7",
                        "statement": "Expand bus lanes across city",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "0981f8ed-9d1c-46c1-8f92-f30137d597c8",
                        "statement": "Create a ferry service from Toronto to Burlington via Etobicoke, Mississauga and Oakville",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "e2918cd7-7f28-4923-881d-2915f3fa24af",
                        "statement": "Explore a \u201cScarborough to Mississauga ferry service\u201d",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "d9860b62-6781-4859-8621-67dd038c10d0",
                        "statement": "Appoint a member of the TTC union to the TTC Board, ensuring employee representation and fostering collaboration between management and frontline workers.",
                        "candidateId": "g.mammoliti"
                    }
                ]
            },
            {
                "name": "Improve TTC Safety",
                "policies": [
                    {
                        "id": "02fcd8b4-5ab9-47dd-830a-018baa172e88",
                        "statement": "Adding safety barriers",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "44116ae7-b634-4360-848b-02b312d82b5f",
                        "statement": "Install cameras and loudspeakers on each bus",
                        "candidateId": "e.sniedzins"
                    },
                    {
                        "id": "0aa39900-cb99-416f-bbc1-332ff4dfa725",
                        "statement": "Add TTC barriers and guards with more police in troubled areas",
                        "candidateId": "r.chan"
                    },
                    {
                        "id": "1278ebb7-34b5-4d60-9b0a-cd160f8fd220",
                        "statement": "Transform TTC toll booths into full-service help stations staffed with paramedics, social workers and mental health specialists",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "65d5d3b9-db36-4e8b-adb8-503b5da50f03",
                        "statement": "Authorize special constables to bring tasers on TTC",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "0157d25b-50ce-43ae-9838-8ecd6c7d56cc",
                        "statement": "Safer subways with more staff",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "b2209a3e-bffb-4bb0-ab31-f104b8801068",
                        "statement": "Establish a dedicated transit division within the TTC that focuses on enhancing safety, customer service, and efficiency",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "ac95aa28-404b-4ffa-a632-77d0a314f623",
                        "statement": "Increase the presence of mobile crisis professionals at TTC stations to respond to medical emergencies and provide immediate care to individuals experiencing mental health crises or substance abuse issues",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "b39dd8a8-853d-42b5-b056-cd2742aab3e2",
                        "statement": "Pair social workers with transit officers, introduce community ambassadors on the network and install platform edge doors at subway stations.",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "6debb95a-c11c-4c84-a972-013a886a5b6b",
                        "statement": "Introduce an 'assist' button on TTC vehicles; install more cameras",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "635ee2da-69e7-4c7d-84a9-b9610a7da04f",
                        "statement": "Deploy 40 new special constables to hotspot TTC stations. He would also install platform edge doors at subway stations",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "7b46e82a-df0d-4c01-9a24-b72ebdbc8f56",
                        "statement": "Increase the presence of TTC personnel to enhance safety and deter criminal activities on public transit.",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "15240c44-a9ef-4e11-beb1-b91ac59748fc",
                        "statement": "Provide additional equipment for TTC Special Constables including body worn cameras",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "4f10587e-0eb4-41ed-aca4-8be74b6ee56e",
                        "statement": "Upgrade police radios to ensure they work on TTC",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "7f99310c-7a1c-4ceb-bb35-e4550f01a87c",
                        "statement": "Implement technological solutions, such as surveillance cameras and emergency call systems, to improve passenger safety.",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "88598510-8e45-4e1c-887c-bb2982aa79bd",
                        "statement": "Install rails on all TTC stations",
                        "candidateId": "r.tull"
                    }
                ]
            },
            {
                "name": "Free or Cheaper TTC",
                "policies": [
                    {
                        "id": "3ed51495-8ca8-4cc9-9c1f-d456f508d510",
                        "statement": "Sustainable transit, freeze fares",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "c826a67e-9629-4982-b629-5ade46dda71a",
                        "statement": "Lowering transit fares",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "e422f91b-a6c1-409c-bd75-ae08cc763c0f",
                        "statement": "Make TTC free; but users must get an app that will play advertisements while riding subway",
                        "candidateId": "h.desai"
                    },
                    {
                        "id": "dbdb256b-a345-416a-ae78-51ccf9f05eee",
                        "statement": "Hop on/hop off free transit in downtown;",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "d3cffe83-e002-47f2-ab5f-1f41ec485e07",
                        "statement": "Seniors ride the TTC for free every Monday between 10 a.m. and 3 p.m.",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "73583323-3203-4857-a928-9aff5a812c1b",
                        "statement": "TTC free for seniors",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "b2f90ab5-071b-4927-a6b6-dd19f9e19dc2",
                        "statement": "Free ttc",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "a9767d76-bc41-4fa4-937e-609b9a442a82",
                        "statement": "Free TTC",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "a420e026-b1a7-4a10-ab54-ff9ea0e422d9",
                        "statement": "Free TTC to those over 55 and under 18",
                        "candidateId": "x.gong"
                    },
                    {
                        "id": "f4b56e3f-b1ba-4c1b-9b3e-7d8e88d0da65",
                        "statement": "Freeze ttc fares for three years",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "27c6f1ee-302f-49a1-83ba-98466acf5ebe",
                        "statement": "Free TTC",
                        "candidateId": "j.winter"
                    },
                    {
                        "id": "ae735ce4-e1b8-4818-8352-46eca1fd6ae1",
                        "statement": "Free TTC - supported through additional private advertising",
                        "candidateId": "b.acton"
                    },
                    {
                        "id": "30bc66ef-519f-48c8-8f49-d805fa0303f8",
                        "statement": "Implement fare equity for neighborhoods that rely on public transit",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "3d693ef6-e2a5-4c8a-9487-64b3a5320781",
                        "statement": "Free off-peak transit for seniors",
                        "candidateId": "h.he"
                    },
                    {
                        "id": "f1b7b995-4734-494d-a79d-6165fa53eb4d",
                        "statement": "Expand the discounted fare pass to include all seniors while lowering their pay-as-you-go fares to $2.10.",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "9aba5c55-44f3-4a07-8741-3cdf339e30e2",
                        "statement": "Reduce the price of single-ride fares to $2 on 501 streetcars and on the Scarborough SRT reroutes until the new bus network is operational.",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "28a9e8c7-5ee9-41ae-975d-d20e1f981986",
                        "statement": "Reverse most recent TTC fare hike",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "c49f60be-2c57-479d-b68b-0dc07e7b21b7",
                        "statement": "Seniors and Wheel-Trans users ride for free.",
                        "candidateId": "m.hunter"
                    }
                ]
            },
            {
                "name": "Improve Internet Connectivity on the TTC",
                "policies": [
                    {
                        "id": "c6e5a98d-3740-42bf-abf2-44d7e640842b",
                        "statement": "Free WIFI",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "349a83ff-5407-4007-8f03-1695ea58e670",
                        "statement": "Ensure cellular connectivity at all stations",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "2fbd91f1-7288-4e6d-bdd8-7ec077d1b63f",
                        "statement": "Cell service across TTC",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "78934cfe-272e-4e05-9f3b-b1b1f69b6d2c",
                        "statement": "Cell service on the TTC",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "4b4f3deb-1830-432e-abfa-16b6e19c358d",
                        "statement": "Cell service",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "8660e957-ca92-412f-adb4-945a1af30d7b",
                        "statement": "Free WIFI and cell service on TTC",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "7dbedd2a-482c-4388-a16f-f17c91a9560d",
                        "statement": "Free WIFI to all commuters",
                        "candidateId": "e.sniedzins"
                    },
                    {
                        "id": "b3fb6080-15b5-469b-93b7-215c53ae69b9",
                        "statement": "Free WIFI on TTC",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "56ef290d-e330-4903-88f8-11a2eed58a3a",
                        "statement": "Bring cell service to all TTC users on the subway network",
                        "candidateId": "b.bradford"
                    }
                ]
            },
            {
                "name": "Reverse TTC cuts",
                "policies": [
                    {
                        "id": "84f853d4-7f6e-4d7b-8b2d-f807e781db41",
                        "statement": "Reversing recent TTC service cuts while focusing on improving reliability and cleanliness",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "c7bbf544-2f58-4d98-a614-75402f9e98af",
                        "statement": "Reverse TTC cuts",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "43c8a7bf-b84e-4985-a811-8896b503539e",
                        "statement": "Reverse TTC service cuts",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "62f5d2b6-c8f5-48c7-ba87-1fcc4bf3d961",
                        "statement": "Restore TTC service to pre-pandemic levels by the end of a first term",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Expanding subway service hours",
                "policies": [
                    {
                        "id": "2b5deccc-4aa3-4418-9b01-cacea00d53b3",
                        "statement": "Expanding subway service until 4am on Friday/Saturday",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "9a87ce8b-df63-468c-97a4-e312682afd26",
                        "statement": "Subway service would begin half hour earlier at 5:30 a.m. six days per week.",
                        "candidateId": "m.hunter"
                    }
                ]
            },
            {
                "name": "Building more subway stations",
                "policies": [
                    {
                        "id": "8bc29c37-31c5-4074-bea3-fa043927e493",
                        "statement": "More subways",
                        "candidateId": "c.saccoccia"
                    },
                    {
                        "id": "91c0d11e-83e7-4e41-a629-d48da59e0345",
                        "statement": "Build more subways",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "f13805f7-efe4-4dde-8277-b25bb904ed2c",
                        "statement": "Advocate for the province to add a Cummer Station on the five-stop Yonge North subway extension",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "abde3bc0-5f65-4c40-af29-8ab43c278373",
                        "statement": "Begin early planning to extend the Ontario Line north to Don Mills Roads and Sheppard Avenue when it is completed.",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "b7c6cfac-7e6e-4665-bf67-c8c4dc605df0",
                        "statement": "Support construction of the Ontario Line, Eglinton East LRT, Waterfront LRT and the east and west Sheppard subway extensions.",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "eee6835f-5670-4284-a69b-97ecdb551b4e",
                        "statement": "Add more buses and build more subways",
                        "candidateId": "s.srivastava"
                    }
                ]
            },
            {
                "name": "Make TTC fleet electric",
                "policies": [
                    {
                        "id": "afb19412-2e0c-45e7-a264-f7c3d9a45800",
                        "statement": "Transform the entire TTC fleet to electric vehicles",
                        "candidateId": "e.sniedzins"
                    },
                    {
                        "id": "c55123cb-0a40-4a41-8d93-b931d156d029",
                        "statement": "Have more electric buses for TTC",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "e0b344e6-e4d4-4618-9949-bc17ebb622aa",
                        "statement": "Electrify the city\u2019s vehicle fleet",
                        "candidateId": "j.matlow"
                    }
                ]
            }
        ]
    },
    {
        "name": "Roads",
        "metaPolicies": [
            {
                "name": "Expanding Bike Share Network",
                "policies": [
                    {
                        "id": "3fdd9b24-a48b-41e5-bdaf-eadc36b149e6",
                        "statement": "Expand bike share to suburbs",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "f14ef4d9-e10e-4fa8-87c6-07778f22ad54",
                        "statement": "Increasing bike share stations in Scarborough, North York, Etobicoke, and on the Toronto Islands.",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Improving bike lane safety",
                "policies": [
                    {
                        "id": "f18df063-8d8c-48d7-aac3-4b2266e1b24e",
                        "statement": "Elevated bike lane for safety and congestion",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "bda36899-675c-46e6-973b-ed9809886342",
                        "statement": "Elevated bike lane as a water tower/aqueduct",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "beb4a9f2-af59-471a-ac6e-6d1fae1ed2e1",
                        "statement": "Make bike lanes safer by adding signals, lights, and banning e-bikes from using bike lanes",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "9880ee2f-6eef-411c-916a-f88ade4ff978",
                        "statement": "Implementing bidirectional cycling lanes to provide dedicated space for cyclists and e-scooters",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Add road tolls",
                "policies": [
                    {
                        "id": "c109069f-8cff-4274-84bb-aac488e89e58",
                        "statement": "Create incentive based system for reducing commuters from 905 to toronto",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "6c8174ec-2ea6-46b2-85af-9dbe3a0498be",
                        "statement": "Tolls on major arteries into the city to fund infrastructure repairs and upgrades",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "df7015e7-2ed1-4792-8c80-cd1cac9207ff",
                        "statement": "Vehicles entering from the QEW will pay a $3.35 toll (pinned to TTC fare)",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "41f2d70a-d0aa-457d-a7aa-bc6d0a3cabca",
                        "statement": "Add a $0.75 toll to all vehicles entering Toronto from the 401 east and west and north of the 400",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "65a4f1f4-dc79-40bd-9006-83ce85d83867",
                        "statement": "Add a $0.25 toll on the gardiner to help pay for its staggering upkeep",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "5048633d-920f-431d-82b7-9ee9f39b9e5c",
                        "statement": "taxing all downtown drivers to disincentivize driving downtown",
                        "candidateId": "m.nicula"
                    }
                ]
            },
            {
                "name": "Increase traffic enforcement",
                "policies": [
                    {
                        "id": "d04a3c5c-bf09-4927-9070-99351ca84d98",
                        "statement": "Ramp up rush hour enforcement with 20 tow trucks at the ready and increased fines for vehicles parked illegally.",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "87fb6cdd-4b37-4e0d-ae7c-6654efec9dd6",
                        "statement": "Use red light cameras to ticket drivers blocking intersections and implement steep fines for construction debris in traffic lanes and on sidewalks.",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "bc98d6cf-01ac-4ec7-bf0a-3637eed386aa",
                        "statement": "Add 200 more speed cameras across the city",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "92f4b1e8-b1b2-4860-9548-7baf6fae26e4",
                        "statement": "Ensure all speeding tickets are processed, to both crack down on speeders and ensure that revenue raised is going into fixing roads",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "937f561b-824d-4d17-a887-32d10cdbbd58",
                        "statement": "Get tough on ticketing and towing and increase fines",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Improving Road Safety",
                "policies": [
                    {
                        "id": "cabd90e8-2562-4bc3-9322-dd33ea4e477d",
                        "statement": "Better street and intersection design",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "458019ce-2e3b-4172-917f-36fa3b518d8e",
                        "statement": "Redesign most dangerous intersections",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "52cbcf50-cc4a-4bf9-8f52-e83deca6b561",
                        "statement": "Maximize road safety and accessibility for all by creating more bike lanes, wider sidewalks, and pedestrian-only zones.",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "22497a7b-54af-488b-80a8-0a1f582be92d",
                        "statement": "Lower speed limits with expanded community safety zones",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "e0ccb2fb-03ab-486c-a3fe-f17b84ecafee",
                        "statement": "Install more pedestrian crossovers and boost the number of school crossing guards.",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "7d45469a-b2c2-4012-b232-eff0f38fe587",
                        "statement": "Pilot new red light camera technology in school zones  to reduce the speed of traffic on city streets",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "10378028-bb3c-4bb0-873e-8876d711f72d",
                        "statement": "Install more automated enforcement cameras",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "57c23c11-9a79-4c49-a359-9d4efe619f55",
                        "statement": "Bring all transportation services under one umbrella for efficiency and integration",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "ee203102-fb15-4c45-bd8e-d60f4a3a2e12",
                        "statement": "Advocate for road designs that constrain vehicle speeds",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "f2d64945-9e76-4abb-bd51-2105aba4a4e3",
                        "statement": "Equip smartcurbs with heating elements to melt snow and ice, making the streets safer for pedestrians and vehicles.",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Improve Road Construction and Maintenance",
                "policies": [
                    {
                        "id": "07a42e63-a051-4d97-8b0f-efef73081038",
                        "statement": "Coordinate road construction to keep traffic, cyclists and pedestrians moving",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "b139f46a-d6d8-4b20-af90-7cad05b97d84",
                        "statement": "Coordinated approach to construction  to minimize compound lane restrictions and road closures on geo-related corridors",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "64883e90-1424-4d7f-a233-73709b1726de",
                        "statement": "Prohibit lane closures on Richmond, Adelaide and Dundas streets between Bay and Victoria streets during Ontario Line work",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "0e3843e0-c51a-425e-9ec1-c3c19170ad55",
                        "statement": "Improving the efficiency of government work and road maintenance and urban cleanliness",
                        "candidateId": "h.he"
                    },
                    {
                        "id": "32e3a825-05c5-490a-9f03-fca3296a64fa",
                        "statement": "Road work and transit construction would be done on double shifts to finish projects more quickly",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "12a1ec61-98b5-4e1b-bf6d-eef911157116",
                        "statement": "Finish Gardner Expressway rebuild",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "fa5c1b55-80cb-4f46-8277-782ae01100d0",
                        "statement": "Appoint a Congestion Relief Commissioner to co-ordinate construction closures across the city",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "7841f657-ca8e-469f-a667-55662a032831",
                        "statement": "Limit road closures and end weekend closures on the Gardiner Expressway and Don Valley Parkway, with maintenance work done only at night.",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "657a1f2c-35ea-4157-822c-0f1725a26231",
                        "statement": "Double budget for pothole fixing",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "5a1ccca7-e4a1-473c-b511-034a615f8872",
                        "statement": "Rebuild the crumbling section east of Jarvis on the ground to save money",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "7dda37bd-3cc1-420b-8b27-2bda7d46af37",
                        "statement": "Extend construction hours downtown to finish projects quicker",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "67847012-4059-47b7-a392-30ecfcd506f0",
                        "statement": "Ensure the province takes responsibility for the DVP and gardiner",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Add bike lanes",
                "policies": [
                    {
                        "id": "adb9d33d-1942-4031-ac72-aa45eefa7c94",
                        "statement": "Build more bike lanes",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "514be8df-150b-450c-849a-ac4925f3942b",
                        "statement": "Expand bike  lanes across city",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "d0120373-5b44-4020-863c-9d0e6124f46b",
                        "statement": "Expand bike lane network",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "c58f1138-5c9d-4a32-b90c-cbf035dae7cc",
                        "statement": "Building more cycling infrastructure",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "661ac142-b974-4fac-abd3-a9e7c3749027",
                        "statement": "Connect toronto\u2019s bike lanes to make a true city-wide network",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "fbe85e86-7293-4b62-90fa-ac9d1491cb16",
                        "statement": "establish winter bike routes",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "46008b32-ff26-49a5-a4c5-8758f15f838a",
                        "statement": "Building cycling infrastructure to reduce traffic gridlock",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Remove bike lanes",
                "policies": [
                    {
                        "id": "4ff456cd-8cf3-4f68-abf9-1e0e631c2b64",
                        "statement": "Stop the expansion of bike lanes, and get rid of them on major arteries",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "ea09f9f7-8a0d-4868-9949-86e1a4e92e1a",
                        "statement": "Remove bike lanes on Richmond, Adelaide and University",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "cdace3a7-826a-4324-bdda-92cc042cb492",
                        "statement": "Remove bike lanes from University avenue",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "f1ae91ab-d37b-4197-8494-f274f1636a37",
                        "statement": "Remove existing University Avenue bike lanes",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "dd672372-2898-4b0d-a516-82deec54f105",
                        "statement": "Remove bike lanes on main thoroughfares like Bloor/Danforth for safety concerns",
                        "candidateId": "b.acton"
                    },
                    {
                        "id": "4373cc43-0963-4ccc-9529-863dac9f6de8",
                        "statement": "Stop expansion of bike lanes",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "bde9692f-fa08-4e7c-a474-0ca43f2a6ab3",
                        "statement": "Reverse the decision to make the Yonge Street bike lanes permanent",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "d6bc293a-2e14-489b-a598-44d7650a1d4b",
                        "statement": "Deprioritize the Bloor Street West bike lane expansion and suspend other expansion projects",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "07bc3949-f50a-4546-9237-52afac2a7668",
                        "statement": "close bike lanes on Richmond and Adelaide streets during Ontario Line construction",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Other ideas for improving traffic flow",
                "policies": [
                    {
                        "id": "657a86a6-0784-4021-b61d-9b70f5f3df22",
                        "statement": "Free up road space to improve traffic flow by removing parking and bike lanes",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "76c30507-0f7c-4def-ba4e-30151f62015a",
                        "statement": "Create more charging stations for EVs and phase out gas station permits",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "e7c8d539-4e8c-4af7-a3cf-c733462607b5",
                        "statement": "Implement Smart curbs to greatly improve traffic flow which uses sensors to detect available parking spaces",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "2e5a21de-eed1-437e-8763-39671a3924a5",
                        "statement": "Create a public option of city-run uber",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "d827a53b-ed38-45ab-9eba-c72222ef1daa",
                        "statement": "Widening all major roads",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "a6319fdf-5313-4a49-972e-1d7c55e3b839",
                        "statement": "Remove redundant traffic lights and adopt a more coordinated traffic signal program",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "7d8a13da-6bc3-4cd3-9650-132a3b265dc8",
                        "statement": "No more trucks on the 401",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "d1ea9e8b-a0fc-42f4-a346-c9021bf839bb",
                        "statement": "Deploy 200 traffic wardens to choke points during rush hour and major events",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "e20edfa4-cdce-4b9b-a4a8-15e8d9972f0f",
                        "statement": "Redeploy 200 parking enforcement officers to key intersections.",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "4461c134-ff6f-4c70-afc2-34866493f7eb",
                        "statement": "Lane filtering to increase traffic flow",
                        "candidateId": "r.chan"
                    },
                    {
                        "id": "92b48119-e428-4138-926b-25a54cafbee3",
                        "statement": "pledged to review occasional closures of the DVP and Lake Shore Boulevard for cycling",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "a3e759b7-0301-4299-9894-f004b6c1b08b",
                        "statement": "Increase fines for illegally stopped vehicles",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "cb1f8e93-44d8-42bd-955e-b67294fdfffd",
                        "statement": "Install adaptive traffic light technology for better flow",
                        "candidateId": "s.srivastava"
                    }
                ]
            },
            {
                "name": "Remove King Street Transit Priority Corridor",
                "policies": [
                    {
                        "id": "73375054-8697-4bef-8e8e-4a996dca1bc1",
                        "statement": "Scrap the King Street Transit Priority Corridor, which limits vehicle traffic and emphasizes streetcars and bicycles",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "df6c349d-0257-4da5-a878-60a6dacd27b0",
                        "statement": "Terminate king street pilot project",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "55f13b05-f9bc-4210-be95-6e329e37ef3c",
                        "statement": "Terminate King Street pilot project",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "edb36d77-4112-4004-b9a9-2d871a714669",
                        "statement": "reopen King Street to through traffic",
                        "candidateId": "a.furey"
                    }
                ]
            }
        ]
    },
    {
        "name": "Food security",
        "metaPolicies": [
            {
                "name": "Repurpose land and infrastructure",
                "policies": [
                    {
                        "id": "26503916-0d4c-43af-a2e2-8d6e921755a6",
                        "statement": "Use vacant lots for farming to make food for the people",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "60039d36-f75d-4ec1-b061-f2c1c0bb711b",
                        "statement": "Develop a program to convert vacant lots and underutilized lands into community gardens and urban farms",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "571607f4-1b04-4f51-9ba8-79092da8589c",
                        "statement": "Prioritizing local food production using elevated bike lanes as terraced urban farm",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "fea958f0-03ff-45d9-bfe3-f08d38080739",
                        "statement": "Transform under-utilized green space into food banks",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Increase Partnerships with Foodbanks and other Local Stakeholders",
                "policies": [
                    {
                        "id": "94390bb7-e84d-4b25-9af7-8c95564e5c5e",
                        "statement": "Create a non-profit food delivery service working for local farmers or food co-ops.",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "e0efe665-0c66-4d26-8f6b-506d814031ee",
                        "statement": "Leverage local partnerships for more urban farms and markets to address food insecurity",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "b1188b24-31c9-4e5d-b402-5f623ea403a8",
                        "statement": "Push grocery chains to donate instead of throw out food",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "e9834582-8a1a-49c8-b0ee-69ad085900a2",
                        "statement": "Redistribute  $900 million of wasted food",
                        "candidateId": "p.dcruze"
                    },
                    {
                        "id": "d7bc0517-9154-487f-a878-add3f3863d7c",
                        "statement": "Spend $2 million more on the Community Partnership and Investment Program",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Create and support community gardens, urban farms",
                "policies": [
                    {
                        "id": "f47aad5c-5ff8-46a7-8bd7-a9280c0f89ae",
                        "statement": "Offer more opportunities for community gardens",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "36577ec4-1a1f-4fee-bcd2-466503b43b2b",
                        "statement": "Create communal gardens as a source of food for locals",
                        "candidateId": "k.langenfeld"
                    },
                    {
                        "id": "43060cdc-3740-4478-b64f-4d92435e237f",
                        "statement": "Invest in more funding for food banks, provide them with more space",
                        "candidateId": "a.bailao"
                    }
                ]
            }
        ]
    },
    {
        "name": "Public Infrastructure",
        "metaPolicies": [
            {
                "name": "Improving City-Wide Construction",
                "policies": [
                    {
                        "id": "fabd08a3-0b79-4cf8-81cc-38763dde003e",
                        "statement": "Allow construction for 24 hours a day, but prohibit construction between 9pm and 7am within 100m of residential areas",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "deadd40a-19cf-43a8-9cb1-a7542386abd4",
                        "statement": "Require all public works projects to apply smart construction solutions or pay a permit charge for lane usage",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "e68f33e0-68d2-4708-8f31-ab1ac7ac456f",
                        "statement": "Call on the federal and provincial governments to expand skilled trade immigration to give city\u2019s construction workforce a boost",
                        "candidateId": "m.saunders"
                    }
                ]
            },
            {
                "name": "Free WIFI",
                "policies": [
                    {
                        "id": "49a7173b-b84a-45fe-991d-b62668f3f11c",
                        "statement": "Ensure access to internet",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "80e7b2bb-d00a-4b38-9047-ffc3895a0fef",
                        "statement": "Provide free WiFi citywide",
                        "candidateId": "a.alam"
                    },
                    {
                        "id": "15df4b99-1332-489c-88d6-bfd9eba3acc0",
                        "statement": "Provide free WiFi in select parks",
                        "candidateId": "m.saunders"
                    }
                ]
            },
            {
                "name": "Public Infrastructure Maintenance and Improvements",
                "policies": [
                    {
                        "id": "92db5a49-0fa9-4e6d-ad23-85f6b2c31c93",
                        "statement": "Refurbish and fix all aging infrastructure",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "249a8b1e-2ee9-4c8e-9fa3-e9635b1478bd",
                        "statement": "Use data and technology to improve city services",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "ad5aa37b-25df-476c-ac7e-342e8254ccb3",
                        "statement": "leverage the ConnectTO project to create a decentralized and community-driven approach to internet infrastructure",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Improve accessibility and access to facilities",
                "policies": [
                    {
                        "id": "d3b6e3d5-95f5-4871-9976-cdc6a0ee4d81",
                        "statement": "Ensure parks, public and third spaces have equitable access for all",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "74b7237a-f703-4ce2-8507-cc2944e4416a",
                        "statement": "Winterize park washrooms and prioritize litter bin clearing and clean water fountains.",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "1dad629f-6191-435c-87ce-d2002105c55b",
                        "statement": "Ensure park facilities like washrooms and water fountains are cleaner and better maintained.",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "1cea17ab-b205-45e0-8de0-74e347fda2c3",
                        "statement": "Increase the number of public washrooms and their accessibility",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "2118cd1d-228a-46bf-aaf4-cb5291a24863",
                        "statement": "All public benches will be replaced with beanbags for citizen comfort",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "4be556e4-4833-4aed-985c-d909e790d70a",
                        "statement": "Create ambassadors who will tour the parks and talk to encampment residents informing them of alternative housing options",
                        "candidateId": "s.climenhaga"
                    },
                    {
                        "id": "88bd98b5-5fec-415c-90e4-84de618a5ff4",
                        "statement": "Expand 311 services and creating physical locations for them",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "298844d5-2cc5-47da-8c83-8b687117c75f",
                        "statement": "Use schools as community centres on evenings and weekends",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Improve Snow Clearing",
                "policies": [
                    {
                        "id": "3c0c6f2d-49e7-4153-af2e-d2f89f0e4446",
                        "statement": "Increase the winter operations budget by more than 10%",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "ae8ac546-342e-4076-b858-682b75661fbd",
                        "statement": "Have snow dam removal across city",
                        "candidateId": "r.chan"
                    },
                    {
                        "id": "cd4bcef0-7c23-4360-ab82-e189b01be016",
                        "statement": "Spend more on modern snow-clearing equipment",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "879ca4fa-fe9c-4e0c-a66d-a8b4ca18c4b4",
                        "statement": "Study bicycle lane use during winter",
                        "candidateId": "r.chan"
                    }
                ]
            },
            {
                "name": "Solutions to Parking",
                "policies": [
                    {
                        "id": "a471822d-dd79-4fbf-95cb-89eb0cffdcf4",
                        "statement": "Build underground parking lots and cancel surface parking lots",
                        "candidateId": "h.he"
                    }
                ]
            },
            {
                "name": "Expanding Waste Management Solutions",
                "policies": [
                    {
                        "id": "d3a710e0-d439-4e75-86b3-6233d8cfa79e",
                        "statement": "Partner with local businesses to collect old e-waste and furniture to be re-used",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "c49a8982-b0cf-4237-b145-fcf92acf5a77",
                        "statement": "Ensure all Torontonians have easy access to  recycling and organic waste collection",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "d2f0e50c-d3b3-4c7d-ac6d-96013c54d5da",
                        "statement": "Create a\u00a0new blue bin system for recycling metals and plastics",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "2b9f8a7e-d6b9-4087-b78c-b1a3b50baf55",
                        "statement": "Sell our garbage and make products from recycled waste to address budget shortfall",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "34261ed2-1a54-444a-af88-bf24f9897fbc",
                        "statement": "Promote waste reduction",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "27059835-6119-4634-a04f-825bd53c735e",
                        "statement": "Create a homeowner incentive program for recycling",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "6733ce15-54a1-43db-bcad-8dd33de9c20e",
                        "statement": "Establish a city-wide composting program to add composting bins to households and businesses",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "9cab7f7c-50f4-4965-ad38-edf2f09bc197",
                        "statement": "Partner with community gardens and urban farms to collect and use the composted material.",
                        "candidateId": "c.brown"
                    }
                ]
            }
        ]
    },
    {
        "name": "Parks",
        "metaPolicies": [
            {
                "name": "Construct more parks and recreational spaces",
                "policies": [
                    {
                        "id": "4a22d135-da85-4190-a0b2-7b4c3dbcb4dd",
                        "statement": "Build more parks and recreation centers",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "afb89fc3-2af0-4944-8c51-77e3b79fbe33",
                        "statement": "Build more recreational facilities, such as cricket pitches, to meet the growing demands of communities",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "84af4218-f3d0-4a7e-99e3-9673b61b3f90",
                        "statement": "Meet the demand for more parks and recreation services",
                        "candidateId": "m.lamoureux"
                    },
                    {
                        "id": "3e8a533b-de09-4916-85b8-ed29c71d36fc",
                        "statement": "Build 68 new parkettes and playgrounds",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "5476b745-6787-44cd-89e2-b52a9d35d40e",
                        "statement": "Create more off-leash dog parks",
                        "candidateId": "f.damico"
                    },
                    {
                        "id": "90d4b9c1-d60f-443e-a08f-f100d43e7d05",
                        "statement": "Use public lands to make communities more liveable\u00a0 with projects like the Scarborough Meadoway trails",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "8c30d75f-0aa6-46bc-b0ff-81884bc2a763",
                        "statement": "Expand access to recreational programs and city-owned pools.",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "bfabc098-f172-4d22-aaed-3f0804dd0bff",
                        "statement": "Build more splash pads and open them earlier in the summer season",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "8a256491-91fd-4c7d-88c8-28f05bbd0a73",
                        "statement": "Build a new West Scarborough Rail Path adjacent to the Line 2 subway between Victoria Park and Kennedy stations.",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "72684976-b971-4899-b915-8f37a825c7d4",
                        "statement": "Boost investments in trails, playgrounds and parks, especially where new developments are being built.",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "aac40e77-f698-43a4-8f5c-b0ced9aa6e01",
                        "statement": "Collect money from developers to build new parks",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "75d979bb-7c8e-4b52-8924-6bf159f190c3",
                        "statement": "Plant more trees and reverse cuts to the city\u2019s tree maintenance program",
                        "candidateId": "m.hunter"
                    }
                ]
            },
            {
                "name": "Permit drinking in parks",
                "policies": [
                    {
                        "id": "2d777e33-a769-40c7-bb40-5584236e5f85",
                        "statement": "Allow residents to drink alcohol in parks legally",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "8f792531-5559-4f85-9462-e775cab95358",
                        "statement": "Permit drinking in city parks",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "c781de22-9014-4ab3-9a22-44b76809c35e",
                        "statement": "Allow residents to drink alcohol in city parks, with conditions",
                        "candidateId": "j.matlow"
                    }
                ]
            }
        ]
    },
    {
        "name": "Social Services",
        "metaPolicies": [
            {
                "name": "Make Ontario Place Private",
                "policies": [
                    {
                        "id": "ecd86f01-8993-42bf-88f8-b74d59095099",
                        "statement": "Make Ontario Place redevelopment even bigger in support of Ford's plan",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "7ddace5c-595a-4453-a211-d72c3f7be00c",
                        "statement": "Strike a balance between private and public interests, ensuring Ontario Place is still publicly accessible",
                        "candidateId": "c.caesarchavannes"
                    }
                ]
            },
            {
                "name": "Create more library locations",
                "policies": [
                    {
                        "id": "69e90bd1-2b30-45c2-832a-25b6efedc8ea",
                        "statement": "Open 74 new locations for public library and health services",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "899b9157-dc23-4d66-b0ec-1767c4002f3f",
                        "statement": "Create more satellite library branches and youth hubs",
                        "candidateId": "m.hunter"
                    }
                ]
            },
            {
                "name": "Increase library hours, open libraries on Sunday",
                "policies": [
                    {
                        "id": "d8f19f1d-c9f3-426b-8497-74532d07583e",
                        "statement": "Ensure all libraries are open on Sundays",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "40a15082-a915-4100-bddf-166320165d6c",
                        "statement": "Keep public libraries open Sundays",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "e09e7d22-0cd6-46b2-b4a3-3ae94fa2c0be",
                        "statement": "Keep libraries open on Sundays year round",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "2b894795-6e63-4dfe-9d0c-d92b9347687d",
                        "statement": "Extend library hours  and have them stay open on Sundays",
                        "candidateId": "o.chow"
                    }
                ]
            },
            {
                "name": "Expand library offerings via partnerships",
                "policies": [
                    {
                        "id": "0ff2ee92-3709-4f45-9f3b-014578c6c951",
                        "statement": "Use $2 million to expand the Toronto Public Library Museum + Arts Pass, and making that funding permanent.",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "6fbc02f7-f4a7-42fa-b960-f013a3ea1884",
                        "statement": "Partner with artists groups, local businesses and organizations to offer discounts and special offers  to TPL cardholders. Have businesses pay a fee to participate in the program",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "3970a0a2-f636-42be-8500-da4a4b3e61c6",
                        "statement": "Have libraries partner with local healthcare providers and social work organizations to provide referrals and resources",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "6e3653f7-0758-4c1f-9584-966b5817ff33",
                        "statement": "Partner with local arts and cultural organizations to use library card to grant users free or discounted access to events such as concerts, theater performances, museum exhibits",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Funding and financial policies for arts and culture",
                "policies": [
                    {
                        "id": "91c2bf08-9956-4905-b2be-8ed763c8af40",
                        "statement": "Fund more multicultural festivals",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "bbbee582-c886-427c-8966-1714621679d1",
                        "statement": "Increase per capita arts and culture spending by 2% annually",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "91bb32ce-1e75-41a4-9028-fb647eddd96a",
                        "statement": "Offer tax breaks to landlords who rent spaces to arts organizations at below-market rates.",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "795b26b8-ba62-458d-987c-d2a981f82c3f",
                        "statement": "Equitably distribute arts funding through the city",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "204fb0af-e2d2-4ae9-b10e-a946d3106632",
                        "statement": "Increase arts funding by cutting spending on external consultants",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "c2f4061c-ba82-4dac-a41e-6bcfb17a4156",
                        "statement": "Exempt all creative arts facilities from property tax increases",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "4147d23a-53dc-4129-b104-88e07fbebc02",
                        "statement": "Create more affordable spaces for artists and community culture events",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "34bf96e7-9aca-48b7-ab82-c2b8a1ac43ce",
                        "statement": "Ensure living wages and accessible social services so artists and creative professionals can flourish in our city",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "24be289f-9287-48be-825c-58faa3969ed5",
                        "statement": "Reduce permit and application fees, and create more opportunities for artists and musicians",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Support arts and culture programming",
                "policies": [
                    {
                        "id": "24ed7ab5-fff6-4b7d-9585-90a7b1ea3d2d",
                        "statement": "Equitably distribute Toronto Arts Council to areas outside of the downtown core",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "e1ce4add-fdd1-4d6b-bc1b-fce77fd84b2e",
                        "statement": "Create a culture commissioner to spearhead and coordinate arts community",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "39210e07-8135-401b-81a0-c53f54f82630",
                        "statement": "Revive Live Music in Toronto",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "6ba9bdb5-3ff1-4f80-9f52-92337b19aa00",
                        "statement": "Create accessible social services and recovery areas for concert venues, nightclubs, bars and restaurants to promote the well-being of both employees and customers",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "81837118-88a7-43ca-a7b3-b7f89f9d59fb",
                        "statement": "Make it easier for buskers to perform in public spaces",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "75712715-e125-45cc-a906-4f9c4725a5f7",
                        "statement": "Rename Yonge-Dundas Square as \u201cLightfoot Music Square\u201d, \u00a0and build a permanent stage in the square that would be used to host Canadian musicians and promote Canadian music events",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "6388ef40-c8be-4e6e-9180-8b737d22b7b4",
                        "statement": "Establish Leisure, Arts, Culture and Entertainment department to manage community land trusts of mixed development properties and venues",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "2afc9673-fed2-41bc-8a89-56d85e3ba9f5",
                        "statement": "Have two annual concerts at Nathan Philips to support local artists and musicians",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "9159db8e-a804-4bea-8d42-ee1de936689d",
                        "statement": "allow \u201cregular street parties, outdoor performances, farmers\u2019 markets and festivals\u201d on evenings and weekends along the stretch of Queen Street closed for Ontario Line construction.",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Keep Ontario Place Public",
                "policies": [
                    {
                        "id": "84462b8d-e1de-4474-ab17-428b898eda5d",
                        "statement": "Cancel Doug Ford's Ontario Place plan, keep Science Centre where it is",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "1e63e48a-c886-4051-ab49-9e85234f2910",
                        "statement": "Oppose the privatization of Ontario Place",
                        "candidateId": "k.langenfeld"
                    },
                    {
                        "id": "a564409e-026e-4094-a304-7f2b1d57b34a",
                        "statement": "End the privatization of the waterfront",
                        "candidateId": "b.graff"
                    },
                    {
                        "id": "d7d5dd71-d2fa-480a-8d35-acde240a500e",
                        "statement": "Make Ontario Place public and convert it into park",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "e05ca827-37ff-4d61-bec7-827b419b529f",
                        "statement": "Keep Ontario Place as a public park",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "b320830b-7536-43a7-8b03-73a0a9c449a0",
                        "statement": "Prioritizing saving Ontario Place\u2019s west island",
                        "candidateId": "s.mann"
                    }
                ]
            },
            {
                "name": "Ontario Science Centre",
                "policies": [
                    {
                        "id": "7944e702-c08a-4153-a927-9ce8965d9626",
                        "statement": "Stop Doug Ford's plan for a private spa by withholding 16 acres of city-owned property to ensure public access",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "aeca4cea-2257-4474-a9a3-258c11adb7a2",
                        "statement": "Keep the Science Centre where it is",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "e041ddbb-1908-40bf-a35b-44b69b2d418c",
                        "statement": "Hold public consultation on the relocation of the Science Centre",
                        "candidateId": "r.davis"
                    },
                    {
                        "id": "679ef5cb-72ff-414f-b79a-f9a29217e6bb",
                        "statement": "Bring the Science Centre to Ontario Place",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "8cdd72ec-f147-4073-8f55-575541da32d1",
                        "statement": "Transform the Science Centre to educate and inform on climate change",
                        "candidateId": "c.brown"
                    }
                ]
            }
        ]
    },
    {
        "name": "Environment",
        "metaPolicies": [
            {
                "name": "Green Development",
                "policies": [
                    {
                        "id": "496f28fb-4d0b-4d0a-a42c-490604eac89c",
                        "statement": "Incentivize use of electric construction vehicles",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "cf4fea99-3808-41d6-b99a-63d2e9deedad",
                        "statement": "Build smarter by mandating all construction runs off electric energy by 2028",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "8ad250b3-27cc-4883-9f60-0297dc9d3519",
                        "statement": "Adopt sustainable development principles",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "9d502aed-4c77-4189-b2ec-deffdbc5348d",
                        "statement": "Maintain the Toronto Green Standard for new buildings",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "a0f28871-c629-4d45-ba72-7177f260da83",
                        "statement": "Improve building waste reduction standards in future versions, and advocate for it to apply to all buildings and major additions",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "01076fc3-3f41-4b4f-8979-f84e84a506a7",
                        "statement": "Implement green infrastructure solutions",
                        "candidateId": "p.singh"
                    }
                ]
            },
            {
                "name": "Supporting Renewable Energy",
                "policies": [
                    {
                        "id": "95912179-d5ae-4935-affb-3f5d964a9034",
                        "statement": "Build a geothermal plant",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "ca80552d-8f68-4206-bacf-832e897da369",
                        "statement": "Encourage solar panel use",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "34672888-26f9-4372-ba39-d440debc55b2",
                        "statement": "Promote solar power and make CN Tower run purely on solar as a symbol",
                        "candidateId": "j.atkinson"
                    },
                    {
                        "id": "7681698a-8354-40a3-bdcb-b1a6849b16dd",
                        "statement": "Create a municipal green new deal to combat climate change",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "aa5f03be-84df-4f73-9d64-53132790ef60",
                        "statement": "Invest in renewable energy",
                        "candidateId": "p.singh"
                    },
                    {
                        "id": "9fb12a4e-7fdc-40ac-a031-9bf6d34fbedb",
                        "statement": "Impose a corporate commercial parking levy to pay for green initiatives.",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Investing in Retrofitting Buildings",
                "policies": [
                    {
                        "id": "2568e2b6-14db-4791-9e40-b3f57e793f73",
                        "statement": "Prioritize older multi-unit buildings for energy audits and retrofit financing for combined heating & cooling systems",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "d234d5f7-09a0-4e10-be3e-1a7aaa887d07",
                        "statement": "Invest $200 million annually to retrofit buildings",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "91e2e2b3-37c8-41a9-aed8-7d46564aadf1",
                        "statement": "Expand Toronto\u2019s building retrofit programs to help homeowners and building owners reduce their emissions.",
                        "candidateId": "o.chow"
                    }
                ]
            },
            {
                "name": "Natural Resource Policy",
                "policies": [
                    {
                        "id": "ce0e8e15-32ab-4605-81e8-644c378265d5",
                        "statement": "Introduce a \u201cgreen zone\u201d as a pilot project to harken environmental change",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "4539bdae-42b7-44f9-9ae2-9747c5a22808",
                        "statement": "Recognize Toronto as the freshwater capital of the world by creating the Ontario Water Centre at Ontario Place",
                        "candidateId": "s.mann"
                    },
                    {
                        "id": "2c94efb2-e085-4fb1-8763-e34157c40796",
                        "statement": "Save the greenbelt by negotiating with the Feds to protect where the province has failed",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "920e7dd3-50d9-41a2-8c5d-892fc118ee45",
                        "statement": "Focus on making Toronto Net Zero",
                        "candidateId": "j.winter"
                    },
                    {
                        "id": "2d0f046e-fd15-4e1e-b8da-7194d35dd566",
                        "statement": "Promote sustainable land use and green infrastructure",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "d575ef1c-da8d-4471-be30-a83275e8dc2a",
                        "statement": "Push back against urban sprawl and intervene at Ontario Land Tribunal",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "5c056321-ffd5-4e20-973e-14b1641ee9a5",
                        "statement": "Protect wildlife",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "64fa0fa3-44eb-4aa6-9f00-78bc3cdf7d6e",
                        "statement": "Adopt a green gravel standard and procurement policy to reduce gravel mining in greenbelt",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "df134cca-329d-41ab-84c7-3755c7e522d8",
                        "statement": "Clean up lake ontario",
                        "candidateId": "j.winter"
                    },
                    {
                        "id": "f3893189-7903-43c0-9019-50c3c307943b",
                        "statement": "Declare access to fresh clean water a human right",
                        "candidateId": "s.mann"
                    }
                ]
            },
            {
                "name": "Anti-Green Development",
                "policies": [
                    {
                        "id": "c6136c7a-4d5e-418b-8d43-fd54ca7085fa",
                        "statement": "end funding for TransformTO, the city\u2019s climate change strategy",
                        "candidateId": "a.furey"
                    }
                ]
            }
        ]
    },
    {
        "name": "Police and Safety",
        "metaPolicies": [
            {
                "name": "Maintain Current Police Funding",
                "policies": [
                    {
                        "id": "7ae9cb36-68c8-453b-b101-338472415aa4",
                        "statement": "Maintain current funding levels for police",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "7b03af70-b241-4c56-9c13-e56ddaf8d574",
                        "statement": "Keep police funding stable",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "9bf60983-b94c-47e6-aaba-5c1d9be35a4d",
                        "statement": "In-depth review of policing, NO defunding",
                        "candidateId": "b.acton"
                    }
                ]
            },
            {
                "name": "Defund the Police",
                "policies": [
                    {
                        "id": "2f239582-8b1b-460c-8a41-46802067cda2",
                        "statement": "Defund and abolish police",
                        "candidateId": "g.allen"
                    },
                    {
                        "id": "bc5cf25b-039e-4cdf-b256-74e1aa6e3b27",
                        "statement": "Defund the police",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "f4990da8-257c-483c-9ec8-25856857fc03",
                        "statement": "Put a three-year freeze on the $1.16 billion Toronto police budget to boost funding for social programs",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "53915025-73a2-40c0-b1b4-1863951ecd16",
                        "statement": "Raccoons will be trained to become our police, firemen, and EMTs.",
                        "candidateId": "m.straus"
                    },
                    {
                        "id": "fe29154f-2b45-474a-a053-621de4306718",
                        "statement": "Establish a $115 million fund to address risk factors for criminal behaviour using funds from the police budget",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Tougher Bail Conditions",
                "policies": [
                    {
                        "id": "5b4f5714-dabe-4412-acd1-78b3349e2255",
                        "statement": "Support bail reform to stop re-offenders",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "2e485487-24ba-4e15-a9e2-5e14fc2307fa",
                        "statement": "Have tougher bail conditions for gun offenders",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "520708a1-8776-4f0a-95a4-ae97602e90e3",
                        "statement": "Reform bail to end \u201ccatch-and-release policies\u201d for violent offenders",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "ac8bb6a8-758b-4a0a-9b07-0b72707f10a8",
                        "statement": "Have tougher bail conditions for repeat violent offenders and reduce access to handguns",
                        "candidateId": "m.hunter"
                    }
                ]
            },
            {
                "name": "Supporting Vulnerable Populations",
                "policies": [
                    {
                        "id": "654603d7-cd97-4bb2-aa2d-580e82705841",
                        "statement": "Educate people on crime and foster schools, health, and social support systems",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "8b51766b-195f-438f-a905-e89a1f4b6d2d",
                        "statement": "More social programs addressing inequality, poverty and social exclusion to address safety and security",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "23018acb-8036-4c70-b4e1-d35b192a25bb",
                        "statement": "Expand community outreach and scope",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "6a3cff97-93d6-4253-82f0-896a58acde9c",
                        "statement": "Have more warming centres",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "e1c9e2bd-a5ca-4b8c-9070-e663313f7929",
                        "statement": "Create Toronto Police Services Women's Division for gender-sensitive policing",
                        "candidateId": "h.desai"
                    },
                    {
                        "id": "71e52cfd-200b-4643-9afa-c2b368b3acee",
                        "statement": "Inform public on the existence of vulnerable people",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "66acb1d7-9b2c-45c9-af36-d984463f0bfa",
                        "statement": "Have 24/7 support to residents in crisis and those living in park encampments",
                        "candidateId": "m.saunders"
                    }
                ]
            },
            {
                "name": "Expanding Youth Rehabilitation Programs",
                "policies": [
                    {
                        "id": "7142a7e4-d0cf-466b-9f1f-d32e1ce60efd",
                        "statement": "Give police tranquilizer guns instead",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "d5280551-e893-44b4-8472-b0d3fbf1a703",
                        "statement": "create 10 youth empowerment centres to teach skilled trades in high crime areas",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "b3ef79f8-6c14-4ad0-94c2-8246b51d54a3",
                        "statement": "Add tradeshops to youth detention centres",
                        "candidateId": "r.tull"
                    },
                    {
                        "id": "4b5b1c98-e9da-4ea9-a964-13f53d4d1a38",
                        "statement": "Prioritize youth programs",
                        "candidateId": "m.hunter"
                    }
                ]
            },
            {
                "name": "Improve response times and community outreach",
                "policies": [
                    {
                        "id": "effd37ef-2483-42d7-bfc1-c36ef6575e51",
                        "statement": "Expand mobile crisis response teams citywide",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "f539fd0a-e10d-438f-a952-1a0da1ed89ac",
                        "statement": "Expand crisis response and outreach",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "6d6fe576-201e-40fd-a083-04cd2a40f565",
                        "statement": "Strengthen community policing by promoting trust and cooperation between officers and the community",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "534e5d27-e12e-4a89-8b37-6a66047a9a34",
                        "statement": "Expand of the Toronto police neighbourhood community officers program",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "e46f1f34-440c-498b-86ac-87dc966d2e52",
                        "statement": "Shorten 911 call wait times",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "b45d239a-db43-4fa6-9ad5-ef2d4c9f7b95",
                        "statement": "ensure 90 per cent of 911 calls are answered within 15 seconds and 95 per cent within 20 seconds.",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "5b3ee7cf-3b75-410a-9c9a-b3b4637df86f",
                        "statement": "Improving communication and understanding between police and community by conducting regular town hall meetings",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "ac75de6a-6878-4aee-adfa-1903eefaf0fc",
                        "statement": "Remove all tent encampments in parks",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "3557b400-8c12-4462-83e7-ff3cc9cb6b83",
                        "statement": "Increase the number of mobile crisis response teams to provide more consistent care for vulnerable people",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "d6ed7967-e6f6-4916-859d-fbf5e0d051e3",
                        "statement": "Ensure the Toronto Community Crisis Service covers the whole city",
                        "candidateId": "a.bailao"
                    }
                ]
            },
            {
                "name": "Preventing and fighting crime",
                "policies": [
                    {
                        "id": "7b262b97-157f-4c4c-8ecd-c389ca5d4777",
                        "statement": "Prioritize prevention and addressing route causes of crime",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "42d5ac6b-87bc-417d-bc1a-cea5b85e43ca",
                        "statement": "Work with all levels of government to address root causes of crime, while also",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "5b66bfe2-d5bc-4d5b-b762-d62c13236b47",
                        "statement": "Implement community-based prevention programs to address gun violence",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "617c635d-4f78-4da9-ba62-44bd011c4a0a",
                        "statement": "Force drug dealers to take their own drugs",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "5baf98af-c112-4f86-9112-6d3d7f47a056",
                        "statement": "Address gang activity by enhancing intelligence gathering, sharing information with law enforcement",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "ee5c6817-cdb6-4cd0-9482-2ed8002c6a04",
                        "statement": "Collaborate with technology experts and law enforcement agencies to develop cybersecurity strategies",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "45fe7df4-53f3-4462-a8e0-c0da72a69cd5",
                        "statement": "Increase punishments and penalties",
                        "candidateId": "m.nicula"
                    },
                    {
                        "id": "8264264f-4abe-4201-9871-3d4ac3ead781",
                        "statement": "Prevent loitering by enforcing existing bylaws",
                        "candidateId": "m.saunders"
                    }
                ]
            },
            {
                "name": "Increase police funding/activity",
                "policies": [
                    {
                        "id": "b7a9caf8-a0fc-433c-b99c-fc3aadc17902",
                        "statement": "Add police in schools to keep kids safe",
                        "candidateId": "x.gong"
                    },
                    {
                        "id": "2e40b106-8de8-465d-a930-a600b8045e7e",
                        "statement": "Protect our children",
                        "candidateId": "c.saccoccia"
                    },
                    {
                        "id": "3dc32b45-7ee3-4ff7-8df4-331cce7f3f87",
                        "statement": "Increase the number of special constables by 200",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "e14ab19d-2287-4dbb-94ad-92b7e98f7399",
                        "statement": "Hire 1000 new police officers, deployed 24/7 and in all subway stations",
                        "candidateId": "x.gong"
                    },
                    {
                        "id": "8dc5c970-e738-4ba0-8409-599808fdc587",
                        "statement": "Hire 500 more police officers, more boots on the streets",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "d17bcc1a-04f8-484f-b801-92dfd6413cc3",
                        "statement": "Increase police budget to hire 225 more officers and have them patrol every subway station",
                        "candidateId": "m.leliever"
                    },
                    {
                        "id": "6c8250cd-729c-473e-b461-78522676c57e",
                        "statement": "Solve major crime in three months and increase salary for officers",
                        "candidateId": "a.alam"
                    },
                    {
                        "id": "e2f9309c-5960-4d7f-a65b-38c2e93bd29a",
                        "statement": "Increase security patrols by police in parks",
                        "candidateId": "a.furey"
                    }
                ]
            },
            {
                "name": "Improving and Supporting Specialized Mental Health Units",
                "policies": [
                    {
                        "id": "5699e3da-f945-4cd0-9843-62349f5a6dd0",
                        "statement": "Support alternatives to police response to mental health calls",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "024819fc-23d3-44a8-9f13-3c683893d14d",
                        "statement": "Combine mental health workers and police to address violent and nonviolent crime",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "61d91fbd-82a4-4e37-8e62-99fda1ccb13f",
                        "statement": "Increase tools and training for police to deal with mental health crises and domestic abuse",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "80b3ee01-bd73-4f25-8245-d5977bc0348e",
                        "statement": "Recruit more mental health workers to augment police",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "a41c9976-6890-4d77-aee0-4cc0f47c91b9",
                        "statement": "Promote diversion programs and alternatives to incarceration for individuals with mental health and addiction issues",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "a9ca7bd4-1d90-4618-a3e0-5c5fa68ebd97",
                        "statement": "Create new city agency called There for You Toronto to provide resources to residents in mental distress",
                        "candidateId": "b.bradford"
                    },
                    {
                        "id": "dcb5c995-8fd7-4e9a-a884-cd56a26ce76f",
                        "statement": "Enhance mental health training",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "a5874266-86d1-47e0-a769-8a9a90a4d7c3",
                        "statement": "develop specialized mental health units",
                        "candidateId": "g.mammoliti"
                    },
                    {
                        "id": "47ae5c79-e83f-47bc-8359-ef00b7162dca",
                        "statement": "Invest in community partners that focus on mental health, homelessness and root causes of violence",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "d3d573b3-0872-4047-b8ba-9a7061d6a8de",
                        "statement": "Expand deployment of mental health crisis teams that specialize in de-escalation.",
                        "candidateId": "j.matlow"
                    }
                ]
            }
        ]
    },
    {
        "name": "Mental Health and Drugs",
        "metaPolicies": [
            {
                "name": "Implement more funding and worker support",
                "policies": [
                    {
                        "id": "983db061-11f8-4db9-8102-e970fb36922a",
                        "statement": "Improve wage and working conditions for social workers and mental health specialists by increasing salaries and benefits",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "a911e65e-d602-4732-b269-0e85e9a3db45",
                        "statement": "Provide additional training and professional development opportunities, and offering incentives for staff to work in high-need areas",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "ad69dbaf-edfe-485c-8f64-9c78266ad84a",
                        "statement": "Promote naxalone use to frontline workers",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "2d1f059a-f68a-4d0e-83d0-9bc50dcc0e7c",
                        "statement": "Hire a chief mental health officer",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "d623cadf-219d-4d6c-98b7-9575262a2404",
                        "statement": "Start a \u201cHOPE\u201d lottery where funds from tickets go to help the homeless",
                        "candidateId": "f.dangelo"
                    },
                    {
                        "id": "13366efd-ecac-4df4-8254-0b3f489f7249",
                        "statement": "Build a sustained mental health response capacity",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "377cc271-b465-4a79-8140-23025d57c18f",
                        "statement": "Prioritize investment in front line staff with a customer service approach",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "a2194193-888e-4d14-b417-cc0b52aecf0d",
                        "statement": "Invest in expanding paramedicine program and the homemakers and nurses services program",
                        "candidateId": "j.matlow"
                    }
                ]
            },
            {
                "name": "Address Mental Health and Crisis Services",
                "policies": [
                    {
                        "id": "6fb1dda1-c496-4287-894f-54bd0347998b",
                        "statement": "Expand Toronto community crisis centre to cover the whole city",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "fcf395c4-a5ed-46aa-9392-102f574ca1d9",
                        "statement": "Establish more safe adult day shelters across the city and expand services",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "959a7e4e-51b1-441e-8636-7e6df667c988",
                        "statement": "Establish campuses of care for public health and safety",
                        "candidateId": "c.brown"
                    },
                    {
                        "id": "1d76626f-9906-4cc6-be10-bbd42ab7c586",
                        "statement": "Create new 24/7 respite spaces, where people can access a shower, meals, a bed, and access resources and critical services",
                        "candidateId": "o.chow"
                    },
                    {
                        "id": "224331fc-fe19-4a08-a88b-5a4a09a88536",
                        "statement": "Start a 24/7 mental health, health care, social therapy and daycare service in all community centres in all communities",
                        "candidateId": "j.letonja"
                    },
                    {
                        "id": "d1692bd6-c350-4263-871d-9db1d13bce08",
                        "statement": "Re-launch Toronto's mental health support strategy",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "1aff227b-49eb-4660-b5ce-29d09669e80b",
                        "statement": "Promote mental health access",
                        "candidateId": "b.abdusalam"
                    },
                    {
                        "id": "6f20dd2d-975b-4086-a713-1891037575f0",
                        "statement": "Create a long-term mental health and addiction strategy",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "1b6c201a-8cec-4432-8c35-d8a40fe2a9d1",
                        "statement": "Establish a public health and safety commission, and integrate all services under one umbrella",
                        "candidateId": "c.brown"
                    }
                ]
            },
            {
                "name": "Update Drug Policy",
                "policies": [
                    {
                        "id": "04a3d619-078e-43e0-ac67-6c017a70fcde",
                        "statement": "Increase accessibility to safe injection sites",
                        "candidateId": "m.charlton"
                    },
                    {
                        "id": "ca093f94-58bb-4cb2-b40f-7ba965d63630",
                        "statement": "Safe injection sites",
                        "candidateId": "j.tunnery"
                    },
                    {
                        "id": "0f36780a-db80-4bb6-a81c-4f892c7fcc08",
                        "statement": "Provide free space in city-owned buildings for non-profits already offering addictions services",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "f83a746a-3936-4df7-9d28-f7676c385312",
                        "statement": "Stop plans for further supervised drug consumption sites and reallocate that money to treatment centres",
                        "candidateId": "a.furey"
                    },
                    {
                        "id": "2b2f2794-7dd9-47a1-bc9e-9840423762dc",
                        "statement": "Update Toronto's overdose prevention plan",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "357d99bf-42f4-49dc-8072-6c22ac45181a",
                        "statement": "Make changes at supervised drug consumption sites, including cracking down on improperly disposed needles near facilities",
                        "candidateId": "m.saunders"
                    },
                    {
                        "id": "f71d3fd2-ba33-4779-aaad-ff62459ba184",
                        "statement": "Decriminalize certain drugs for personal use",
                        "candidateId": "m.hunter"
                    }
                ]
            }
        ]
    },
    {
        "name": "Children and Seniors",
        "metaPolicies": [
            {
                "name": "More Childcare and After School Programs",
                "policies": [
                    {
                        "id": "eed9cec6-b49a-4e4d-b224-3f9901300172",
                        "statement": "Implement $10 a day daycare in all priority communities",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "8dad8e67-ee1d-45bc-bb0f-04acee55c468",
                        "statement": "Create more affordable daycare options",
                        "candidateId": "s.srivastava"
                    },
                    {
                        "id": "c4a44235-2065-49db-9878-8c1fa3766f6b",
                        "statement": "Provide free, quality, 24-hour childcare",
                        "candidateId": "k.vadivelu"
                    },
                    {
                        "id": "c1f3745b-961c-4739-a987-0a09dbd651ed",
                        "statement": "Open 34 new childcare centres",
                        "candidateId": "m.hunter"
                    },
                    {
                        "id": "39ae12f4-90f2-42b2-acbf-eb8d55120d03",
                        "statement": "Provide free after-school recreation programs",
                        "candidateId": "a.perruzza"
                    },
                    {
                        "id": "53545498-407f-44e6-82da-c278fde7eb1c",
                        "statement": "Promote and support the Toronto For All education initiative",
                        "candidateId": "c.caesarchavannes"
                    },
                    {
                        "id": "39aab226-986f-4d63-92bc-e74dedf6c899",
                        "statement": "Work with TDSB and TCHC to support programs at school buildings and in community centres",
                        "candidateId": "c.caesarchavannes"
                    }
                ]
            },
            {
                "name": "Improving Nutritional Standards",
                "policies": [
                    {
                        "id": "8e46921b-bef7-4dba-a88a-3a1577a22dca",
                        "statement": "Have $6 a day school lunch subsidies",
                        "candidateId": "x.gong"
                    },
                    {
                        "id": "05a23483-f1dd-4624-987c-f5f286b618e6",
                        "statement": "Provide Free Lunch for primary and secondary school students",
                        "candidateId": "h.he"
                    },
                    {
                        "id": "7b485e49-49e4-4969-ba09-9daaee3eb88a",
                        "statement": "Implement a school-breakfast program",
                        "candidateId": "k.vadivelu"
                    }
                ]
            },
            {
                "name": "Making Life for Comfortable for Seniors",
                "policies": [
                    {
                        "id": "cb75991e-6745-4980-ae26-1d3a1e839ed5",
                        "statement": "Construct more/new senior housing",
                        "candidateId": "x.gong"
                    },
                    {
                        "id": "1beaacf3-20f6-41fc-9cd5-b473793caa0e",
                        "statement": "Have an accelerated application process for senior housing",
                        "candidateId": "h.he"
                    },
                    {
                        "id": "60cd4dbb-bb43-4ac8-b564-c29e6e4b0d6f",
                        "statement": "Have Senior Care and \"Retire In Place\" for access to care",
                        "candidateId": "d.atkinson"
                    },
                    {
                        "id": "d9fda374-6e11-4e1d-895c-286c631f3dcb",
                        "statement": "Provide door-to-door check-ins for seniors using the existing vaccine ambassador program",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "2adfe72b-bc00-4740-bcc8-05f383fb07e7",
                        "statement": "Have neighbourhood program for healthy seniors",
                        "candidateId": "a.bailao"
                    },
                    {
                        "id": "6cf0b6f9-c3ae-48de-be3f-e77e2dddbfc3",
                        "statement": "Bring back cafes in the city-run long-term care homes",
                        "candidateId": "j.matlow"
                    },
                    {
                        "id": "a20b4422-1506-4403-a111-17c35b026404",
                        "statement": "Providing daycare services for the elderly",
                        "candidateId": "h.he"
                    }
                ]
            }
        ]
    }
]