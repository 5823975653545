import {
  Button,
  ChakraProvider,
  Container,
  Heading,
  Link,
} from "@chakra-ui/react";
import React from "react";
import { ToolSelectionModal } from "./ToolSelectionModal";

export const App = () => {
  const [showToolSelection, setShowToolSelection] = React.useState(false);

  return (
    <ChakraProvider>
      <Container
        maxW="container.md"
        gap={4}
        flexDirection="column"
        display="flex"
        paddingTop={6}
      >
        <Heading>Explore 2023 Toronto Mayoral Candidates</Heading>

        <p>
          Policy Explorer is a tool developed by{" "}
          <Link
            href="https://www.pleasevotetoronto.ca"
            color="blue.600"
            textDecoration="underline"
            isExternal
          >
            Please Vote Toronto
          </Link>{" "}
          to help sort through the incredible quantity of candidates with
          substantive platforms during the Toronto Municipal Election of 2023.
          We felt overwhelmed by the number of candidates. When we dug into the
          policy proposals and platforms of each candidate, our heads started
          spinning. We developed this tool to organize and clarify our own
          perspectives to help filter candidates in time for election day.
        </p>
        <p>
          Our tool allows you to filter and separate all the policy statements
          made by each candidate. We sourced the statements from candidate’s
          websites and news organizations. We hope this will help you navigate
          the ballot on Monday morning!
        </p>
        <p>
          Each general statement has a drop down option that allows you to fine
          tune and filter the specifics. For example, in the property tax
          section, you’re presented with three clickable options: freeze
          property taxes, reduce property taxes or increase property taxes. You
          can choose to refine your options and see the actual statements by
          each prospective candidate on the tax issue, to know exactly where
          they stand.
        </p>
        <p>
          We are a small team so there is every possibility that we missed some
          statements and some candidates. For that, we apologize.
        </p>
        <p>
          As an alpha-test, we are not collecting any data, aside from usability
          feedback.
        </p>
        <p>
          <strong>
            Heads up, there's a lot of content here. It will take approximately
            20 minutes to complete.
          </strong>
        </p>

        <Button onClick={() => setShowToolSelection(true)}>
          Start Exploring
        </Button>

        <p>
          — Made with ❤️ for the Democractic Process by{" "}
          <Link
            href="https://www.dromartistcollective.org/rashid"
            color="blue.600"
            textDecoration="underline"
            isExternal
          >
            Rashid
          </Link>{" "}
          and{" "}
          <Link
            href="https://valentin.tsatsk.in"
            color="blue.600"
            textDecoration="underline"
            isExternal
          >
            Valentin
          </Link>
          .
        </p>

        {showToolSelection && (
          <ToolSelectionModal onClose={() => setShowToolSelection(false)} />
        )}
      </Container>
    </ChakraProvider>
  );
};
