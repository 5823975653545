import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Candidate,
  MetaPolicy,
  NewPolicy,
  PoliciesByCandidateAndCategory,
  Policy2,
  candidatesById,
  policiesByCandidateAndCategory,
  policiesFile,
} from "./policies";

const categoryPageOrder = [
  "Housing",
  "Governance",
  "Economic development",
  "Property Taxes",
  "TTC",
  "Roads",
  "Food security",
  "Public Infrastructure",
  "Parks",
  "Social Services",
  "Environment",
  "Police and Safety",
  "Mental Health and Drugs",
  "Children and Seniors",
] as const;

type Category = (typeof categoryPageOrder)[number];

const categoryDescriptions: {
  [key in Category]: string;
} = {
  Housing: "Policies about housing supply, cost and quality.",
  Governance: "Policies framed around the way our government works.",
  "Economic development":
    "Policies framed around affordability and business development.",
  "Property Taxes": "Policies about increasing or decreasing property taxes.",
  TTC: "Policies about our public transit system.",
  Roads: "Policies about road infrastructure, including bikes.",
  "Food security": "Policies framed around access to food.",
  "Public Infrastructure":
    "The essential infrastructure that allows the city to function, like garbage and snow removal.",
  Parks: "Policies framed around parks",
  "Social Services":
    "The services the city provides that we all use, like libraries and arts programming.",
  "Police and Safety":
    "Policies framed around our police services and safety concerns.",
  "Children and Seniors":
    "Policies to benefit quality of life for children and seniors.",
  "Mental Health and Drugs":
    "Policies to augment our mental health and addiction support systems.",
  Environment: "Policies to protect our natural environment.",
};

export const MetaPolicyExplorer = ({ onClose }: { onClose: () => void }) => {
  const [page, setPage] = useState(0);
  const [showCandidates, setShowCandidates] = useState(false);

  const [selectedPolicies, setSelectedPolicies] = useState(
    new Map<string, boolean>()
  );

  useEffect(() => {
    // Load selected policies from local storage
    const json = window.localStorage.getItem("selectedPolicies");
    if (!json) {
      return;
    }

    const parsed = JSON.parse(json);
    setSelectedPolicies(new Map(parsed));
  }, []);

  const handleOnPolicyToggle = (policyIds: string[], checked: boolean) => {
    setSelectedPolicies((prev) => {
      const next = new Map(prev);

      for (const policyId of policyIds) {
        next.set(policyId, checked);
      }

      setTimeout(() => {
        // schedule this later to not block UI and cause latency
        const json = JSON.stringify([...next.entries()]);
        window.localStorage.setItem("selectedPolicies", json);
      }, 0);

      return next;
    });
  };

  const handleResetSelectedPolicies = useCallback(() => {
    setSelectedPolicies(new Map());
    window.localStorage.removeItem("selectedPolicies");
  }, []);

  const handleOnNext = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handleOnBack = useCallback(() => {
    setPage((prev) => {
      if (prev === 0) {
        onClose();
        return prev;
      } else {
        return prev - 1;
      }
    });
  }, []);

  const handleShowCandidatesChange = useCallback(() => {
    setShowCandidates((prev) => !prev);
  }, []);

  const allPolicies = policiesFile.flatMap((category) =>
    category.metaPolicies.flatMap((metaPolicy) => metaPolicy.policies)
  );

  if (page < categoryPageOrder.length) {
    // Policy Selection
    const categoryName = categoryPageOrder[page];
    const policyCategory = policiesFile.find((x) => x.name === categoryName);

    if (!policyCategory) {
      throw new Error("No policy category found");
    }

    const subtitle = categoryDescriptions[categoryName];

    return (
      <PolicySelectionModal
        title={policyCategory.name}
        subtitle={subtitle}
        selectedPolicies={selectedPolicies}
        onPolicyToggle={handleOnPolicyToggle}
        metaPolicies={policyCategory.metaPolicies}
        onBack={handleOnBack}
        onNext={handleOnNext}
        allPolicies={allPolicies}
        policiesByCandidateAndCategory={policiesByCandidateAndCategory}
        showCandidates={showCandidates}
        onToggleShowCandidates={handleShowCandidatesChange}
        page={page}
        onReset={handleResetSelectedPolicies}
      ></PolicySelectionModal>
    );
  } else {
    // Summary page

    return (
      <SummaryModal
        selectedPolicies={selectedPolicies}
        allPolicies={allPolicies}
        onBack={page > 0 ? handleOnBack : undefined}
        onNext={handleOnNext}
        policiesByCandidateAndCategory={policiesByCandidateAndCategory}
      ></SummaryModal>
    );
  }
};

const PolicyCard = ({
  title,
  policies,
  onChange,
  selectedPolicies,
  onPolicyChange,
  showCandidates,
}: {
  title: string;
  policies: Array<NewPolicy>;
  selectedPolicies: Map<string, boolean>;
  showCandidates: boolean;
  onChange: (checked: boolean) => void;
  onPolicyChange: (policyId: string, checked: boolean) => void;
}) => {
  const checked = policies.some((p) => selectedPolicies.get(p.id));

  const handleChecked = () => {
    onChange(!checked);
  };

  return (
    <Card borderColor={checked ? "blue.600" : "transparent"} borderWidth={2}>
      <CardBody display="flex" flexDirection="column" padding={0}>
        <Box padding={5}>
          <Checkbox
            isChecked={checked}
            onChange={handleChecked}
            alignItems="flex-start"
          >
            {title}
          </Checkbox>
        </Box>

        <Accordion allowToggle>
          <AccordionItem borderBottom={0}>
            <AccordionButton>
              <AccordionIcon mr={2} />
              Refine
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Policy</Th>
                    {showCandidates && <Th>Candidate</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {policies.map((policy) => {
                    const candidate = candidatesById.get(policy.candidateId);
                    const checked = selectedPolicies.get(policy.id) ?? false;
                    return (
                      <Tr
                        key={policy.id}
                        onClick={() => onPolicyChange(policy.id, !checked)}
                        cursor="pointer"
                        backgroundColor={checked ? "gray.50" : undefined}
                      >
                        <Td>
                          <Checkbox
                            isChecked={checked}
                            pointerEvents="none" // ensure tr click is not blocked
                          ></Checkbox>
                        </Td>
                        <Td maxWidth="75ch">{policy.statement}</Td>
                        {showCandidates && (
                          <Td>{candidate?.name ?? "Unknown"}</Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </CardBody>
    </Card>
  );
};

const PolicySelectionModal = ({
  title,
  subtitle,
  selectedPolicies,
  metaPolicies,
  allPolicies,
  policiesByCandidateAndCategory,
  onPolicyToggle,
  onNext,
  onBack,
  onToggleShowCandidates,
  showCandidates,
  page,
  onReset,
}: {
  title: string;
  subtitle: string;
  selectedPolicies: Map<string, boolean>;
  metaPolicies: Array<MetaPolicy>;
  allPolicies: Array<Policy2>;
  policiesByCandidateAndCategory: PoliciesByCandidateAndCategory;
  showCandidates: boolean;
  page: number;
  onPolicyToggle(policyIds: string[], checked: boolean): void;
  onToggleShowCandidates: () => void;
  onNext?: () => void;
  onBack?: () => void;
  onReset?: () => void;
}) => {
  const candidates = selectedPoliciesToCandidateIds({
    selectedPolicyIds: Array.from(selectedPolicies.entries())
      .filter(([, checked]) => checked)
      .map(([id]) => id),
    policies: allPolicies,
  }).map(([candidateId, numPolicies]) => {
    // TODO: fix this type casting
    const candidate = candidatesById.get(candidateId) as Candidate;
    return {
      ...candidate,
      numPolicies,
    };
  });

  const sortedCandidates = _.sortBy(candidates, "numPolicies").reverse();
  const headerFlexDirection = useBreakpointValue({
    base: "column" as const,
    sm: "row" as const,
  });

  const showSidebar = useBreakpointValue({
    base: false,
    sm: true,
  });

  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll back to top of page when navigating to a new page
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  const resetConfirmation = useDisclosure();
  const cancelRef = useRef<any>(); // TODO: fix this type

  const handleReset = useCallback(() => {
    resetConfirmation.onClose();
    onReset?.();
  }, [onReset, resetConfirmation]);

  return (
    <Modal
      isOpen={true}
      onClose={console.log.bind(null, "onClose")}
      size="full"
      scrollBehavior="inside"
      motionPreset="none" // prevent animation when navigating between summary and policies
    >
      <ModalOverlay />
      <ModalContent>
        {/* Prevent tooltip from loading on page load by capturing first input: */}
        <input type="text" style={{ width: 0, height: 0 }} aria-hidden></input>
        <ModalHeader>
          <Flex flexDirection={headerFlexDirection} gap={2}>
            <Box flex={1}>
              {title}
              <Text fontSize="sm" fontWeight="normal">
                {subtitle}
              </Text>
            </Box>
            <Tooltip
              hasArrow
              label="Candidate names are hidden by default to encourage you to
                    select policies based soley on the policy statement. We
                    suggest you reveal the candidate names only after you have
                    made your selections."
            >
              <Button onClick={onToggleShowCandidates}>
                <Checkbox
                  isChecked={showCandidates}
                  pointerEvents="none"
                  fontWeight="normal"
                >
                  Reveal Candidates
                </Checkbox>
              </Button>
            </Tooltip>
          </Flex>
        </ModalHeader>
        <ModalBody backgroundColor="gray.200" padding={0}>
          <Flex>
            <Flex
              flexDirection="column"
              flex={1}
              gap={4}
              padding={4}
              marginLeft="auto"
              marginRight="auto"
              maxWidth="120ch"
              ref={topRef}
            >
              <StepProgress
                steps={categoryPageOrder}
                currentStep={page}
              ></StepProgress>

              {metaPolicies.map(({ name, policies }, index) => {
                return (
                  <PolicyCard
                    key={name}
                    title={name}
                    policies={policies}
                    selectedPolicies={selectedPolicies}
                    onChange={(checked) => {
                      onPolicyToggle(
                        policies.map((p) => p.id),
                        checked
                      );
                    }}
                    onPolicyChange={(policyId, checked) => {
                      onPolicyToggle([policyId], checked);
                    }}
                    showCandidates={showCandidates}
                  ></PolicyCard>
                );
              })}
            </Flex>
            <Divider orientation="vertical" />
            {showCandidates && showSidebar && (
              <Box width="33%" backgroundColor="gray.100">
                <Heading size="md" margin={3}>
                  Candidates to Consider
                </Heading>
                {sortedCandidates.length === 0 && (
                  <Text paddingStart={3} paddingEnd={3}>
                    When you select policies, candidates will appear here.
                  </Text>
                )}
                <Flex flexDirection="column" gap={4} margin={3}>
                  {sortedCandidates.length > 0 && (
                    <CandidateSummary
                      selectedPolicies={selectedPolicies}
                      sortedCandidates={sortedCandidates}
                      policiesByCandidateAndCategory={
                        policiesByCandidateAndCategory
                      }
                      onPolicyChange={(policyId, checked) => {
                        onPolicyToggle([policyId], checked);
                      }}
                    />
                  )}
                </Flex>
              </Box>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup width="100%">
            <Button
              colorScheme="red"
              variant="outline"
              onClick={resetConfirmation.onOpen}
            >
              Reset
            </Button>

            <AlertDialog
              isOpen={resetConfirmation.isOpen}
              leastDestructiveRef={cancelRef}
              onClose={resetConfirmation.onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Reset all policy selections?
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    This will clear all selections made across all pages. Are
                    you sure?{" "}
                    <strong>You can't undo this action afterwards.</strong>
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <ButtonGroup>
                      <Button
                        ref={cancelRef}
                        onClick={resetConfirmation.onClose}
                      >
                        Cancel
                      </Button>
                      <Button colorScheme="red" onClick={handleReset}>
                        Reset
                      </Button>
                    </ButtonGroup>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            <Spacer></Spacer>
            <Button isDisabled={!onBack} onClick={onBack}>
              Back
            </Button>
            <Button colorScheme="blue" isDisabled={!onNext} onClick={onNext}>
              Next
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SummaryModal = ({
  policiesByCandidateAndCategory,
  selectedPolicies,
  allPolicies,
  onNext,
  onBack,
}: {
  policiesByCandidateAndCategory: PoliciesByCandidateAndCategory;
  selectedPolicies: Map<string, boolean>;
  allPolicies: Array<Policy2>;
  onNext?: () => void;
  onBack?: () => void;
}) => {
  const candidates = selectedPoliciesToCandidateIds({
    selectedPolicyIds: Array.from(selectedPolicies.entries())
      .filter(([, checked]) => checked)
      .map(([id]) => id),
    policies: allPolicies,
  }).map(([candidateId, numPolicies]) => {
    // TODO: fix this type casting
    const candidate = candidatesById.get(candidateId) as Candidate;
    return {
      ...candidate,
      numPolicies,
    };
  });

  const sortedCanadidates = _.sortBy(candidates, "numPolicies").reverse();

  return (
    <Modal
      isOpen={true}
      onClose={console.log.bind(null, "onClose")}
      size="full"
      scrollBehavior="inside"
      motionPreset="none" // prevent animation when navigating between summary and policies
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Summary
          <Text fontSize="sm" fontWeight="normal">
            The candidates shown here are the ones that match the policies
            you've chosen to support. They are ordered by the number of their
            policies you support.
          </Text>
        </ModalHeader>
        <ModalBody backgroundColor="gray.200" padding={0}>
          <Flex
            flexDirection="column"
            flex={1}
            gap={4}
            padding={4}
            maxWidth="120ch"
            margin="auto"
          >
            <StepProgress
              steps={categoryPageOrder}
              currentStep={categoryPageOrder.length}
            ></StepProgress>

            {sortedCanadidates.length > 0 && (
              <CandidateSummary
                sortedCandidates={sortedCanadidates}
                selectedPolicies={selectedPolicies}
                policiesByCandidateAndCategory={policiesByCandidateAndCategory}
              />
            )}

            {sortedCanadidates.length === 0 && (
              <Card>
                <CardBody>
                  <Alert status="warning">
                    <AlertIcon />
                    You haven't selected any policies so we can't show you any
                    candidates. Please go back and select some policies.
                  </Alert>
                </CardBody>
              </Card>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button isDisabled={!onBack} onClick={onBack}>
              Back
            </Button>
            <Button colorScheme="blue" isDisabled={true} onClick={onNext}>
              Done
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const selectedPoliciesToCandidateIds = ({
  selectedPolicyIds,
  policies,
}: {
  selectedPolicyIds: string[];
  policies: NewPolicy[];
}) => {
  const policyMap = new Map<string, NewPolicy>(
    policies.map((policy) => [policy.id, policy])
  );

  const selectedCandidateIds = selectedPolicyIds.map((policyId) => {
    const policy = policyMap.get(policyId);
    if (!policy) {
      throw new Error(`Policy ${policyId} not found`);
    }

    return policy.candidateId;
  });

  return Object.entries(_.countBy(selectedCandidateIds, (id) => id));
};

const CandidateSummary = ({
  sortedCandidates,
  selectedPolicies,
  policiesByCandidateAndCategory,
  onPolicyChange,
}: {
  sortedCandidates: (Candidate & { numPolicies: number })[];
  selectedPolicies: Map<string, boolean>;
  policiesByCandidateAndCategory: PoliciesByCandidateAndCategory;
  onPolicyChange?: (policyId: string, checked: boolean) => void;
}) => {
  const totalPoliciesSupported = _.sumBy(sortedCandidates, "numPolicies");

  return (
    <>
      {sortedCandidates.map((candidate) => {
        const policyCategories = policiesByCandidateAndCategory.get(
          candidate.id
        );
        return (
          <CandidateCard
            key={candidate.id}
            name={candidate.name}
            selectedPolicies={selectedPolicies}
            policyCategories={policyCategories || []}
            numPoliciesSupported={candidate.numPolicies}
            totalPoliciesSupported={totalPoliciesSupported}
            onPolicyChange={onPolicyChange}
          ></CandidateCard>
        );
      })}
    </>
  );
};

export const CandidateCard = ({
  name,
  policyCategories,
  selectedPolicies,
  numPoliciesSupported,
  totalPoliciesSupported,
  onPolicyChange,
  hideCheckbox,
}: {
  name: string;
  policyCategories: Array<{ category: string; policies: Array<NewPolicy> }>;
  selectedPolicies?: Map<string, boolean>;
  numPoliciesSupported?: number;
  totalPoliciesSupported?: number;
  hideCheckbox?: boolean;
  onPolicyChange?: (policyId: string, checked: boolean) => void;
}) => {
  return (
    <Card>
      <CardBody display="flex" flexDirection="column" padding={0}>
        <Flex padding={5} alignItems="center" gap={2}>
          <Avatar name={name}></Avatar>
          <Box flex={1}>
            {name}
            {typeof numPoliciesSupported === "number" &&
              typeof totalPoliciesSupported === "number" && (
                <Flex alignItems="center" gap={2}>
                  <Progress
                    value={numPoliciesSupported}
                    max={totalPoliciesSupported}
                    flex={1}
                  />
                  ({numPoliciesSupported}/{totalPoliciesSupported})
                </Flex>
              )}
          </Box>
        </Flex>

        <Accordion allowToggle>
          <AccordionItem borderBottom={0}>
            <AccordionButton>
              <AccordionIcon mr={2} />
              Policies
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Table size="sm">
                <Thead>
                  <Tr>
                    {!hideCheckbox && <Th></Th>}
                    <Th>Category</Th>
                    <Th>Policy</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {policyCategories.flatMap((category) => {
                    return category.policies.map((policy) => {
                      const checked = selectedPolicies?.get(policy.id) ?? false;
                      return (
                        <Tr
                          key={policy.id}
                          backgroundColor={checked ? "gray.50" : undefined}
                          onClick={() => {
                            onPolicyChange?.(policy.id, !checked);
                          }}
                        >
                          {!hideCheckbox && (
                            <Td>
                              <Checkbox
                                isDisabled={!onPolicyChange}
                                isChecked={checked}
                                pointerEvents="none" // ensure tr click is not blocked
                              ></Checkbox>
                            </Td>
                          )}
                          <Td>{category.category}</Td>
                          <Td maxWidth="75ch">{policy.statement}</Td>
                        </Tr>
                      );
                    });
                  })}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </CardBody>
    </Card>
  );
};

const StepProgress = ({
  steps,
  currentStep,
}: {
  steps: ReadonlyArray<string>;
  currentStep: number;
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <Card position="relative" size="sm">
      {isMobile ? (
        <CardBody gap={2} display="flex" flexDirection="column">
          {currentStep == steps.length
            ? "Done! 🎉"
            : `Progress: ${currentStep + 1}/${steps.length}`}
          <Progress value={currentStep + 1} max={steps.length}></Progress>
        </CardBody>
      ) : (
        <Stepper
          size="sm"
          index={currentStep}
          gap={0}
          borderRadius={5}
          padding={2}
        >
          {categoryPageOrder.map((step, index) => {
            return (
              <Step key={index} style={{ gap: 0 }}>
                <StepIndicator bg="white">
                  <StepStatus
                    complete={
                      index == categoryPageOrder.length - 1 ? (
                        "🎉"
                      ) : (
                        <StepIcon />
                      )
                    }
                  />
                </StepIndicator>

                <StepSeparator style={{ margin: 0 }} />
              </Step>
            );
          })}
        </Stepper>
      )}
    </Card>
  );
};
