import {
  StepStatus
} from "./chunk-PTPIN7KF.mjs";
import {
  StepIcon
} from "./chunk-BED63DRY.mjs";
import {
  StepNumber
} from "./chunk-3F3Z3NC5.mjs";
import {
  useStepContext,
  useStepperStyles
} from "./chunk-YVFLN5OD.mjs";

// src/step-indicator.tsx
import { cx } from "@chakra-ui/shared-utils";
import { chakra } from "@chakra-ui/system";
import { jsx } from "react/jsx-runtime";
function StepIndicator(props) {
  const { status } = useStepContext();
  const styles = useStepperStyles();
  return /* @__PURE__ */ jsx(
    chakra.div,
    {
      "data-status": status,
      ...props,
      __css: styles.indicator,
      className: cx("chakra-step__indicator", props.className)
    }
  );
}
function StepIndicatorContent() {
  return /* @__PURE__ */ jsx(
    StepStatus,
    {
      complete: /* @__PURE__ */ jsx(StepIcon, {}),
      incomplete: /* @__PURE__ */ jsx(StepNumber, {}),
      active: /* @__PURE__ */ jsx(StepNumber, {})
    }
  );
}

export {
  StepIndicator,
  StepIndicatorContent
};
