import {
  Badge,
  Card,
  CardBody,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { CandidateExplorer } from "./CandidateExplorer";
import { MetaPolicyExplorer } from "./MetaPolicyExplorer";

export const ToolSelectionModal = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const [modalShown, setModalShown] = useState<
    "selection" | "policyExplorer" | "candidateExplorer"
  >("selection");

  const handleClickPolicyExplorer = useCallback(() => {
    setModalShown("policyExplorer");
  }, []);

  const handleClickCandidateExplorer = useCallback(() => {
    setModalShown("candidateExplorer");
  }, []);

  const handleCloseTool = useCallback(() => {
    setModalShown("selection");
  }, []);

  switch (modalShown) {
    case "selection":
      return (
        <Modal isOpen={true} onClose={onClose} size="md">
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>Select exploration tool</ModalHeader>
            <ModalCloseButton></ModalCloseButton>
            <ModalBody backgroundColor="gray.100" padding={5}>
              <Flex gap={4} flexDirection="column">
                <ToolButton
                  title="Browse by Policy"
                  description="Explore policies and select policies directly, revealing candidates who support them at the end."
                  onClick={handleClickPolicyExplorer}
                  recommended
                ></ToolButton>
                <ToolButton
                  title="Browse by Candidate"
                  description="Browse a list of candidates and explore their policies directly."
                  onClick={handleClickCandidateExplorer}
                ></ToolButton>
              </Flex>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      );
    case "policyExplorer":
      return (
        <MetaPolicyExplorer onClose={handleCloseTool}></MetaPolicyExplorer>
      );
    case "candidateExplorer":
      return <CandidateExplorer onClose={handleCloseTool}></CandidateExplorer>;
  }
};

export const ToolButton = ({
  title,
  description,
  onClick,
  recommended,
}: {
  title: string;
  description: string;
  recommended?: boolean;
  onClick: () => void;
}) => {
  return (
    <Card flex={1} size="sm" onClick={onClick} cursor="pointer">
      <CardBody>
        <Heading size="sm" mb={4}>
          {title}
        </Heading>
        {recommended && (
          <Badge colorScheme="green" mb={2}>
            Recommended
          </Badge>
        )}
        <Text>{description}</Text>
      </CardBody>
    </Card>
  );
};
