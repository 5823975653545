import {
  CheckIcon
} from "./chunk-63XOFNIN.mjs";
import {
  useStepContext,
  useStepperStyles
} from "./chunk-YVFLN5OD.mjs";

// src/step-icon.tsx
import { Icon } from "@chakra-ui/icon";
import { cx } from "@chakra-ui/shared-utils";
import { jsx } from "react/jsx-runtime";
function StepIcon(props) {
  const { status } = useStepContext();
  const styles = useStepperStyles();
  const icon = status === "complete" ? CheckIcon : void 0;
  return /* @__PURE__ */ jsx(
    Icon,
    {
      as: icon,
      __css: styles.icon,
      ...props,
      className: cx("chakra-step__icon", props.className)
    }
  );
}

export {
  StepIcon
};
