import _ from "lodash";
import { Array, Literal, Record, Static, String, Union } from "runtypes";

// TODO: clean up this file

export const CandidateId = Union(
  Literal("b.abdusalam"),
  Literal("b.acton"),
  Literal("e.acquaye"),
  Literal("s.acton"),
  Literal("a.alam"),
  Literal("a.atef"),
  Literal("d.dionysios"),
  Literal("g.allen"),
  Literal("d.atkinson"),
  Literal("j.atkinson"),
  Literal("a.bailao"),
  Literal("j.baking"),
  Literal("b.bankas"),
  Literal("c.beals"),
  Literal("g.benway"),
  Literal("b.bonilla"),
  Literal("b.bradford"),
  Literal("c.brown"),
  Literal("b.buffey"),
  Literal("c.caesarchavannes"),
  Literal("m.carrie"),
  Literal("r.chan"),
  Literal("m.charlton"),
  Literal("d.charltonromero"),
  Literal("o.chow"),
  Literal("l.choy"),
  Literal("k.clarke"),
  Literal("s.climenhaga"),
  Literal("g.cohen"),
  Literal("p.collins"),
  Literal("f.damico"),
  Literal("f.dangelo"),
  Literal("p.dcruze"),
  Literal("r.davis"),
  Literal("s.deb"),
  Literal("h.desai"),
  Literal("c.deville"),
  Literal("s.fenby"),
  Literal("m.forrester"),
  Literal("a.furey"),
  Literal("s.furnival"),
  Literal("i.gamk"),
  Literal("f.gao"),
  Literal("x.gong"),
  Literal("a.goraya"),
  Literal("b.graff"),
  Literal("a.grosman"),
  Literal("j.guglielmin"),
  Literal("d.gulyas"),
  Literal("t.hall"),
  Literal("p.handjis"),
  Literal("h.he"),
  Literal("t.heaps"),
  Literal("m.hossain"),
  Literal("m.hunter"),
  Literal("s.igodan"),
  Literal("s.itohan"),
  Literal("d.irmya"),
  Literal("s.jaffery"),
  Literal("m.jensen"),
  Literal("p.johnston"),
  Literal("w.khan"),
  Literal("s.korovitsyn"),
  Literal("m.lamoureux"),
  Literal("k.langenfeld"),
  Literal("r.lee"),
  Literal("m.leliever"),
  Literal("j.letonja"),
  Literal("n.macleod"),
  Literal("g.mammoliti"),
  Literal("s.mann"),
  Literal("c.marshall"),
  Literal("j.matlow"),
  Literal("f.mohee"),
  Literal("b.murphy"),
  Literal("m.nicula"),
  Literal("j.nowwarah"),
  Literal("a.perruzza"),
  Literal("j.ransome"),
  Literal("d.renee"),
  Literal("w.reodica"),
  Literal("w.rubino"),
  Literal("c.saccoccia"),
  Literal("l.sanders"),
  Literal("m.saunders"),
  Literal("r.schipano"),
  Literal("r.shusterman"),
  Literal("k.singh"),
  Literal("p.singh"),
  Literal("r.sivaneswaran"),
  Literal("e.sniedzins"),
  Literal("s.srivastava"),
  Literal("m.straus"),
  Literal("w.tang"),
  Literal("m.toye"),
  Literal("r.tull"),
  Literal("j.tunnery"),
  Literal("k.vadivelu"),
  Literal("j.weenen"),
  Literal("y.wei"),
  Literal("j.williams"),
  Literal("j.winter"),
  Literal("n.yan"),
  Literal("g.mammaloti")
);

export type CandidateId = Static<typeof CandidateId>;

export type Candidate = {
  id: CandidateId;
  name: string;
  photo: URL;
};

export type Category =
  | "housing"
  | "economic development"
  | "public infrastructure"
  | "access to care"
  | "beauty and entertainment"
  | "governance";

export type Policy = {
  content: string;
  candidateId: CandidateId;
  category: Category;
  id: string;
};

export const candidates: Candidate[] = [
  {
    id: "b.abdusalam",
    name: "Bahira Abdusalam",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.acton",
    name: "Blake Acton",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "e.acquaye",
    name: "Emmanuel Acquaye",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.acton",
    name: "Sharif Ahmed",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.alam",
    name: "Asadul Alam",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "g.allen",
    name: "Gru Allen",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.atef",
    name: "Atef Aly",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "d.dionysios",
    name: "Dionysios Apostolopoulos",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "d.atkinson",
    name: "Darren Atkinson",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.atkinson",
    name: "Jamie Atkinson",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.bailao",
    name: "Ana Bailão",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.baking",
    name: "Jose Baking",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.bankas",
    name: "Ben Bankas",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "c.beals",
    name: "Claudette Beals",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "g.benway",
    name: "Glen Benway",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.bonilla",
    name: "Eliazar Bonilla",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.bradford",
    name: "Brad Bradford",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "c.brown",
    name: "Chloe Brown",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.buffey",
    name: "Brian Buffey",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "c.caesarchavannes",
    name: "Celina Caesar-Chavannes",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.carrie",
    name: "Mason Carrie",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.chan",
    name: "Roland Chan",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.charlton",
    name: "Matti Charlton",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "d.charltonromero",
    name: "Danny Chevalier Romero",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "o.chow",
    name: "Olivia Chow",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "l.choy",
    name: "Logan Choy",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "k.clarke",
    name: "Kevin Clarke",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.climenhaga",
    name: "Sarah Climenhaga",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "g.cohen",
    name: "Gordon Cohen",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "p.collins",
    name: "Paul Collins",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "f.damico",
    name: "Frank D'Amico",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "f.dangelo",
    name: "Frank D'Angelo",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "p.dcruze",
    name: "Phillip D'Cruze",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.davis",
    name: "Rob Davis",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.deb",
    name: "Samson Deb",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "h.desai",
    name: "Habiba Desai",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "c.deville",
    name: "Cory Deville",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.fenby",
    name: "Simryn Fenby",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.forrester",
    name: "Monica Forrester",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.furey",
    name: "Anthony Furey",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.furnival",
    name: "Scott Furnival",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "i.gamk",
    name: "Isabella Gamk",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "f.gao",
    name: "Feng Gao",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "x.gong",
    name: "Xiao Hua Gong",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.goraya",
    name: "Adil Goraya",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.graff",
    name: "Brian Graff",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.grosman",
    name: "Ari Grosman",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.guglielmin",
    name: "James Guglielmin",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "d.gulyas",
    name: "David Gulyas",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "t.hall",
    name: "Thomas Hall",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "p.handjis",
    name: "Peter Handjis",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "h.he",
    name: "Heather He",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "t.heaps",
    name: "Toby Heaps",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.hossain",
    name: "Monowar Hossain",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.hunter",
    name: "Mitzie Hunter",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.igodan",
    name: "Sheila Igodan",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "d.irmya",
    name: "Daniel Irmya",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.jaffery",
    name: "Syed Jaffery",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.jensen",
    name: "Michael Jensen",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "p.johnston",
    name: "Patricia Johnston",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "w.khan",
    name: "Walayat Khan",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.korovitsyn",
    name: "Serge Korovitsyn",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.lamoureux",
    name: "Michael Lamoureux",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "k.langenfeld",
    name: "Kris Langenfeld",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.lee",
    name: "Rick Lee",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.leliever",
    name: "Mark LeLiever",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.letonja",
    name: "John Letonja",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "n.macleod",
    name: "Norman MacLeod",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "g.mammoliti",
    name: "Giorgio Mammoliti",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.mann",
    name: "Steve Mann",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "c.marshall",
    name: "Cleveland Marshall",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.matlow",
    name: "Josh Matlow",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "f.mohee",
    name: "Faizul Mohee",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "b.murphy",
    name: "Bob Murphy",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.nicula",
    name: "Michael Nicula",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.nowwarah",
    name: "Jamil Nowwarah",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "a.perruzza",
    name: "Anthony Perruzza",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.ransome",
    name: "John Ransome",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "d.renee",
    name: "D!ONNE Renée",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "w.reodica",
    name: "Willie Reodica",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "w.rubino",
    name: "Walter Rubino",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "c.saccoccia",
    name: "Chris Saccoccia",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "l.sanders",
    name: "Lyall Sanders",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.saunders",
    name: "Mark Saunders",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.schipano",
    name: "Rocco Schipano",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.shusterman",
    name: "Robert Shusterman",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "k.singh",
    name: "Knia Singh",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "p.singh",
    name: "Partap Dua Singh",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.sivaneswaran",
    name: "Raksheni Sivaneswaran",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "e.sniedzins",
    name: "Erwin Sniedzins",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "s.srivastava",
    name: "Sandeep Srivastava",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.straus",
    name: "Meir Straus",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "w.tang",
    name: "Weizhen Tang",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "m.toye",
    name: "Mitchell Toye",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "r.tull",
    name: "Reginald Tull",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.tunnery",
    name: "Jeffery Tunney",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "k.vadivelu",
    name: "Kiri Vadivelu",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.weenen",
    name: "Jack Weenen",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "y.wei",
    name: "Yuanqian Wei",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.williams",
    name: "Jody Williams",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "j.winter",
    name: "John Winter",
    photo: new URL("http://placekitten.com/200/300"),
  },
  {
    id: "n.yan",
    name: "Nathalie Xian Yi Yan",
    photo: new URL("http://placekitten.com/200/300"),
  },
];

export const candidatesById = new Map<string, Candidate>(
  candidates.map((candidate) => [candidate.id, candidate])
);

export type NewPolicy = {
  id: string;
  statement: string;
  candidateId: CandidateId;
};

const Policy = Record({
  id: String,
  statement: String,
  candidateId: CandidateId,
});
const MetaPolicy = Record({
  name: String,
  policies: Array(Policy),
});
export const PolicyCategory = Record({
  name: String,
  metaPolicies: Array(MetaPolicy),
});

export const PolicyFile = Array(PolicyCategory);

export type PolicyFile = Static<typeof PolicyFile>;
export type PolicyCategory = Static<typeof PolicyCategory>;
export type MetaPolicy = Static<typeof MetaPolicy>;
export type Policy2 = Static<typeof Policy>;

import file from "./policies.json";

// The following will help with uncovering which of the policies failed the CandidateId check
// file.flatMap((x) =>
//   x.metaPolicies.flatMap((y) =>
//     y.policies.map((z) => {
//       console.log(z.candidateId);
//       return CandidateId.check(z.candidateId);
//     })
//   )
// );

export const policiesFile = PolicyFile.check(file);

export const policiesFlattened = policiesFile.flatMap((x) =>
  x.metaPolicies.flatMap((y) =>
    y.policies.map((z) => {
      return {
        candidateId: z.candidateId,
        statement: z.statement,
        id: z.id,
        category: x.name,
      };
    })
  )
);

export type PoliciesByCandidateAndCategory = Map<
  string,
  {
    category: string;
    policies: {
      candidateId: CandidateId;
      statement: string;
      id: string;
      category: string;
    }[];
  }[]
>;

export const policiesByCandidateAndCategory: PoliciesByCandidateAndCategory =
  new Map(
    _.chain(policiesFlattened)
      .groupBy((x) => x.candidateId)
      .mapValues((x) =>
        _.chain(x)
          .groupBy((x) => x.category)
          .entries()
          .map(([k, v]) => ({ category: k, policies: v }))
          .value()
      )
      .entries()
      .value()
  );

export const candidatesAlphabetical = _.chain([
  ...policiesByCandidateAndCategory.keys(),
])
  .map((id) => {
    return candidates.find((c) => c.id === id);
  })
  .sortBy("name")
  // TODO: remove this dangerous typecast
  .value() as Candidate[];
